<template>
    <modal v-model="modalIsOpen" :title="title">
        <template #body>
            <div class="input-group">
                <div class="input-wrapper">
                    <input type="text" v-model="searchText" placeholder="Search for Tickers..." @v-on:keypress.enter="runSearch">
                </div>
                <btn el="button" title="Search" @click="runSearch" type="primary">Search</btn>
            </div>
            <div class="column">
                <spin-loader :showMe="showSpinLoader" />
            </div>

            <section class="table-flex free-form margin-top-small fullwidth ticker-results-container">
                <header class="table-head style-plain tickerEntryHeaders">
                    <ul>
                        <li class="grid-2-12">Ticker</li>
                        <li class="grid-3-12">Name</li>
                        <li class="grid-5-12">Owner</li>
                        <li class="grid-2-12">Actions</li>
                    </ul>
                </header>
                <section class="table-body">
                    <section class="table-row tickerEntry" v-for="tick in searchResults" :key="tick.symbol">
                        <div class="table-cell grid-2-12">
                            <strong>[{{ tick.symbol }}]</strong>
                        </div>
                        <div class="table-cell grid-3-12">{{ tick.name }}</div>
                        <div class="table-cell grid-5-12">{{ tick.ownerName ? tick.ownerName : 'Unknown' }}</div>
                        <div class="table-cell grid-2-12">
                            <btn el="button" title="tick.symbol" @click="handleTickerSelected(tick.symbol)" type="secondary" size="small">Select</btn>
                        </div>
                    </section>
                </section>
                
            </section>

        </template>
        <template #footer>
            <button class="button-secondary" @click="modalIsOpen = false">Cancel</button>
        </template> 
    </modal>    
</template>
<script>

    import { mapState } from 'vuex'
    import { ref } from 'vue'
    import _ from 'lodash'

    export default {
        name: 'TickerSearch',
        emits: ['tickerfound', 'update:isOpen'],
        props: {
            isOpen: { type: Boolean, default: false },
            ownerName: String
        },
        setup(props) {
            const searchText = _.cloneDeep(props.ownerName)
            const showSpinLoader = ref(false)
            const searchResults = ref([])

            return {
                searchText, showSpinLoader, searchResults
            }
        },
        computed: {
            ...mapState({
                user: state => state.userInfo.user.userInfo
            }),
            title() {
                return 'Search Ticker' + (this.ownerName ? ' for ' + this.ownerName : '')
            },
            modalIsOpen: {
                get() {
                    return this.isOpen
                },
                set(val) {
                    //this.searchText = _.cloneDeep(this.ownerName)
                    this.$emit('update:isOpen', val)
                }
            },
        },
        methods: {
            handleTickerSelected(tick) {
                if (tick) {
                    this.$emit('tickerfound', tick)
                    this.modalIsOpen = false
                }
            },
            showTheModal() {
                this.modalIsOpen = true
            },
            closeTheModal() {
                this.modalIsOpen = false
            },
            runSearch(suppressToastMessages) {
                return new Promise((resolve, reject) => {
                    console.log(this.searchText)
                    if (this.searchText) {
                        this.showSpinLoader = true
                        this.$store.dispatch('financials/tickerSearch', { term: this.searchText })
                            .then((results) => {
                                if (results)
                                    this.searchResults = results.filter((t) => { return t.symbol })
                                else
                                    this.searchResults = []
                                this.showSpinLoader = false
                                resolve(results)
                            }).catch(error => {
                                this.showSpinLoader = false
                                this.searchResults = []
                                if (!suppressToastMessages)
                                    this.$toast.add({ severity: 'error', summary: 'Error searching Tickers', detail: error.message, life: 3000 })
                                reject('Error searching Tickers: ' + error.message)
                            })
                    }
                    else {
                        reject('No Search Term')
                        this.searchResults = []
                    }
                })
            }
        },
        mounted() {
            if (this.searchText)
                this.runSearch()
        }
    }
</script>

<style scoped lang="scss">
</style>

<template>
    <form v-if="group.queries.length" class="form-attributes-edit margin-bottom-small">
        <div class="input-group nowrap">
            <div class="input-wrapper">
                <div class="input-field fullwidth">
                    <input v-model="nameModel" class="style-alternate" type="text" autocomplete="off">
                </div>
            </div>
            <spin-loader :showMe="loading" />
            <btn v-if="!loading" el="button" class="small" title="Save" @click.prevent="rename" type="primary">Rename</btn>
        </div>
    </form>
    <section v-if="group.queryID" class="margin-left-min">
        <btn @click="openQueryLink(group.queryID)" :title="'Open a search similar to this one.'" type="icon" icon="external-link-alt"></btn>
    </section>
    <section v-if="group.criteria">
        <applied-filters :criteria="group.criteria" :advanced="true" :readOnly="true" />
    </section>
    <section v-else-if="group.noQueryDescription">
        {{ group.noQueryDescription }}
    </section>
    <section class="margin-bottom-small">
        <div class="flex-rows no-responsive margin-bottom-min">
            <div class="menu-buttons">
                <btn type="text" v-if="selected.length" title="Unselect all in the Search" @click="$emit('unselect', group)">Deselect All</btn>
                <btn type="text" v-if="unselected.length" title="Select all in the Search" @click="$emit('select', group)">Select All</btn>
            </div>
            <div class="menu-buttons place-right">
                <btn type="icon" icon="trash" v-if="selected.length" @click="$emit('delete')" title="Delete the Selected bookmarks"></btn>
                <dropdown v-show="sortedGroupItems.length > 1" ref="sortDropdown" type="icon" icon="fas fa-sort-alt" :useFontAwesome="true" direction="right" :title="'Sort: ' + sortByLabel(sortOptions, sortBy) + (sortBy.asc ? ' (asc)' : ' (desc)')">
                    <template #header>
                        <h3>Sort Bookmarks by: </h3>
                    </template>
                    <template #body>
                        <sort-menu @sortselected="$refs.sortDropdown.closeTheDropDown()" :options="sortOptions" v-model="sortBy"></sort-menu>
                    </template>
                </dropdown>
            </div>
        </div>
        <ul class="list-blocks select">
            <li class="list-item" v-for="(item, idx) in sortedGroupItems" :key="idx">
                <div class="checkbox" :class="'listItem ' + (model[item.objectKey] ? 'is-selected' :  '')">
                    <checkbox v-model="model[item.objectKey]" :label="item.shortName" />
                    <span class="count" :title="'Bookmarked on ' + item.date">
                        <span v-if="!showItemContext && item.queryID" class="margin-left-min">
                            <btn @click="openQueryLink(item.queryID)" :title="'Open a search similar to this one.'" type="icon" icon="external-link-alt"></btn>
                        </span>
                        {{ item.type.charAt(0).toUpperCase() + item.type.slice(1) }}
                    </span>
                </div>
                <section v-if="showItemContext" class="margin-top-min">
                    <section v-if="item.queryID" class="margin-left-min">
                        <btn @click="openQueryLink(item.queryID)" :title="'Open a search similar to this one.'" type="icon" icon="external-link-alt"></btn>
                    </section>
                    <section v-if="item.subsetCriteria">
                        <applied-filters :criteria="item.subsetCriteria" :advanced="true" :readOnly="true" />
                    </section>
                    <section v-else-if="item.noQueryDescription">
                        {{ item.noQueryDescription }}
                    </section>
                </section>
               

            </li>
        </ul>
    </section>
</template>
    <script>
        import SortMenu from '../Menus/SortMenu.vue'
        import sortHelper from '../../mixins/sortHelper.js'
        import _ from 'lodash'
        import SpinLoader from '../baseComponents/SpinLoader.vue'
        import AppliedFilters from '../Search/AppliedFilters.vue'

        export default {
            name: 'bookmarkGroup',
            emits: ['update:modelValue', 'select', 'unselect', 'delete'],
            mixins: [sortHelper],
            components: {
                SortMenu, SpinLoader, AppliedFilters
            },
            props: {
                group: { type: Object },
                modelValue: { type: Object },
                showItemContext: { type: Boolean, default: false }
            },
            data: function () {
                return {
                    loading: false,
                    nameModel: this.group ? this.group.name : '',
                    model: this.modelValue,
                    sortOptions: [
                        { label: 'Date', key: 'date', type: 'Date' },
                        { label: 'Name', key: 'name', type: 'String' },
                        { label: 'Object Type', key: 'objectType', type: 'String' }],
                    sortBy: { key: 'date', asc: false }
                }
            },
            computed: {
                sortedGroupItems: function () {
                    return this.sortItems(this.group.members, this.sortBy);
                },
                selected: function () {
                    var me = this;
                    return _.filter(me.group.members, function (item) { return me.model[item.objectKey] });
                },
                unselected: function () {
                    var me = this;
                    return _.filter(me.group.members, function (item) { return !me.model[item.objectKey] });
                },
            },
            methods: {
                openQueryLink: function (id) {
                    window.open('/Search/Vue?queryID=' + id, '_blank');
                },
                rename: function () {
                    this.loading = true;
                    this.$store.dispatch('bookmarks/updateSubset', { id: this.group.id, ownerID: this.group.ownerID, name: this.nameModel }).then(() => {
                        this.loading = false;
                        this.$toast.add({ severity: 'success', summary: 'Renamed group', detail: 'Renamed to ' + this.nameModel, life: 2000 })
                    }).catch(error => {
                        this.loading = false;
                        this.$toast.add({ severity: 'error', summary: 'Error renaming group', detail: error, life: 3000 })
                    })
                },
            },
            watch: {
                'group.name': function() {
                    this.nameModel = this.group.name;
                }
            }
        
        };
    </script>

    <style scoped lang="scss">
    </style>

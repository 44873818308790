<template>
    <section class="box border frame-xsmall title-people" :class="{'inactive': deactiveInfo}">
        <span class="name">
            <a v-if="detailsURL" target="_blank" :href="detailsURL" :title="name"> {{ shortenedName  }}<span v-if="model.primaryContact" class="badge-circle primary">P</span></a>
            <span v-else :title="name">{{ shortenedName }}<span v-if="model.primaryContact" class="badge-circle primary">P</span></span>

            <span v-if="userResearch || showEdit" class="actions">
                <span v-if="deactiveInfo" class="badge-default error status">Inactive</span>
                <btn el="link" v-if="!editing && detailsURL" target="_blank" :href="'/person/edit/' + model.id" title="Edit" type="icon"><i class="fas fa-edit"></i></btn>
            </span>
        </span>
        <span class="position" :title="title">
            {{ shortenedTitle }}
        </span>
        <span class="email" v-if="!deactiveInfo">
            <i v-if="email" class="fas fa-envelope"></i>
            <a v-if="email" target="_blank" :href="'mailto:' + email" :class="{ 'bad-email': badEmail }" :title="email"> {{ shortenedEmail }} </a>
            <span v-if="emailVerified" class="badge-circle success" :title="toShortDateString(model.email.verifyDate)"><i class="fas fa-check"></i></span>
            <span v-if="badEmail" class="badge-circle error" :title="toShortDateString(model.email.verifyDate)"><i class="fas fa-minus"></i></span>
            <a v-if="emailUrl" :href="emailUrl" target="_blank" class="badge-circle tertiary" title="Email History"><i class="fas fa-history"></i></a>
        </span>
        <span class="phone" v-if="!deactiveInfo">
            <i v-if="fullTel" class="fas fa-phone-alt"></i>
            <a v-if="fullTel" target="_blank" :href="'tel://' + fullTel">{{ fullTel }}</a>
            <span v-if="isMobilePhone" class="important-badge">M</span>
        </span>
        <span v-if="model.error || deactiveInfo" class="errors">
            <span v-if="model.error" class="ui-message error">{{ model.error }}</span>
            <span v-if="deactiveInfo" class="ui-message error">{{ deactiveInfo }}</span>
        </span>
        <template v-if="editing">
            <ul class="list-table columns margin-top hide">
                <li class="border-right">
                    <span class="label">Title:</span>
                    <span class="data margin-right-small">
                        <input v-model="model.title" type="text" />
                    </span>
                </li>
                <li class="border-right" :class="checkboxClass">
                    <span class="label">Primary:</span>
                    <span class="data margin-right-small">
                        <checkbox v-model="model.primaryLOB" />
                    </span>
                </li>
                <li class="no-border-top" :class="checkboxClass">
                    <span class="label">Verified:</span>
                    <span class="data margin-right-small">
                        <checkbox v-model="model.verified" disabled />
                    </span>
                </li>
                <template v-if="advancedMode">
                    <li class="border-right" :class="checkboxClass">
                        <span class="label">Important:</span>
                        <span class="data margin-right-small">
                            <checkbox v-model="model.important" />
                        </span>
                    </li>
                    <li :class="checkboxClass">
                        <span class="label">Active:</span>
                        <span class="data margin-right-small">
                            <checkbox v-model="model.active" ref="chkActive" />
                        </span>
                    </li>
                    <li class="border-right" :class="checkboxClass">
                        <span class="label">Entry Date:</span>
                        <span class="data margin-right-small">
                            {{ toShortDateString(model.entryDate) }} by {{ model.entryUserName }}
                        </span>
                    </li>
                    <li v-show="!(model.changeDate == '0001-01-01T00:00:00' ||  model.entryDate == model.changeDate)" :class="checkboxClass">
                        <span class="label">Change Date:</span>
                        <span class="data margin-right-small">
                            {{ toShortDateString(model.changeDate) }} by {{ model.changeUserName }}
                        </span>
                    </li>
                </template>
            </ul>
            <div v-show="!model.primaryContact" class="flex-columns margin-top-small">
                <btn el="button" title="Delete" @click="deletePerson" type="secondary" size="small"><i class="far fa-times"></i> Delete Person</btn>
            </div>
        </template>
    </section>
</template>
<script>
    import { nextTick, ref, watch } from 'vue'
    import { mapGetters } from 'vuex'
    import { useStore } from 'vuex'
    import _ from 'lodash'

    import Checkbox from '../ui/forms/Checkbox.vue'

    export default {
        name: 'LobEditor',
        components: {
            Checkbox
        },
        props: {
            modelValue: { type: Object },
            inpopup: {
                type: Boolean,
                default: false
            },
            editing: {
                type: Boolean,
                default: false
            },
            advancedMode: {
                type: Boolean,
                default: false
            },            
            objectContext: {
                type: String,
                default: 'company'
            },
            showEdit: {
                type: Boolean,
                default: false
            },

        },
        emits: ['delete', 'primarychange', 'update:modelValue'],       
        computed: {
            ...mapGetters({
                NoSubjectID: 'ais/NoSubjectID',
                NoAttributeID: 'ais/NoAttributeID'
            }),
            user() {
                return this.store.getters['userInfo/currentUser']
            },
            userResearch() {
                return this.user && _.find(this.user.groups, function (g) { return g.id == 9 });
            },
            detailsURL() {
                return this.objectContext == 'person' ? '/company/detailspage/' + this.model.companyID : (this.objectContext == 'company' ? '/person/detailspage/' + this.model.id : '')
            },
            nameField() {
                return this.objectContext == 'person' ? 'companyName' : 'name'
            },
            deactiveInfo() {
                if (!this.model.active) {
                    return (this.model.deactiveReason + ' ' + this.toShortDateString(this.model.deactiveDate)).trim()
                }
                return null
            },
            name() {
                return this.model[this.nameField] ? this.model[this.nameField] : this.model.name
            },
            shortenedName() {
                return this.name ? this.ellipseString(this.name, 28, true) : '';
            },
            title() {
                return this.model.title;
            },
            shortenedTitle() {
                return this.title ? this.ellipseString(this.title, 30, true) : '';
            },
            emailUrl() {
                // absolute URL because redirects lose the query string
                return this.model && this.model.email && this.model.email.id ? 'https://cmdm.berkerynoyes.com/History/details/' + this.model.email.id + '?objectType=email' : null
            },
            email() {
                return this.model && this.model.email ? this.model.email.email : null
            },
            shortenedEmail() {
                return this.email ? this.ellipseString(this.email, 25, true) : 'No Email';
            },
            emailVerified() {
                return this.email ? this.model.email.verified : null
            },
            badEmail() {                
                return this.email ? (this.model.email.verified === false) : null
            },
            fullTel() {
                return this.model && this.model.primaryTel ? this.model.primaryTel.fullTel : null
            },
            isMobilePhone() {
                return this.model && this.model.primaryTel ? this.model.primaryTel.telTypeID == 3 : false
            },
            checkboxClass() {
                return this.advancedMode ? 'grid-6-12' : 'grid-6-12'
            }
        },
        methods: {
            deleteTitle() {
                if (this.model.primaryContact)
                    this.$toast.add({ severity: 'error', summary: 'You can not delete the primary Contact!', detail: this.name, life: 3000 })
                else {
                    this.model.delete = true
                    this.model.dirty = true
                    this.$emit('delete', this.model)
                }
            },
            async setPrimary(primaryContact) {
                this.settingPrimary = true
                await nextTick()
                this.model.primaryContact = primaryContact
                await nextTick()
                this.settingPrimary = false
            },
            async setActive(val) {
                await nextTick()
                this.model.active = val
            },
            async setProp(key, val) {
                await nextTick()
                this.model[key] = val
            },
            addToIIW() {
                this.$toast.add({ severity: 'warning', summary: 'Add to Internet Information Weekly', detail: 'Not Implemented', life: 3000 })
            }
        },
        watch: {
            "model.active": {
                handler(val) {
                    if (!val && this.model.primaryContact) {
                        this.$toast.add({ severity: 'warning', summary: 'You can not deactivate primary contact!', detail: '', life: 3000 })
                        this.setActive(true)
                    }                   
                },
            },
            'model.primaryContact': {
                handler() {
                    this.model.dirty = true

                    if (!this.settingPrimary) {
                        this.$emit("primarychange", this.model)
                    }
                }
            }
        },
        setup(props, context) {
            const store = useStore()

            const model = ref(props.modelValue)
            if (!model.value) {
                model.value = {  }
                context.emit('update:modelValue', model.value)
            }

            watch(model, (newModel) => {
                context.emit('update:modelValue', newModel)
            }, { deep: true })

            const settingPrimary = ref(false)

            return { model, store, settingPrimary }

        }
    }
</script>
<style lang="scss">
    .dimension-container + .dimension-container {
        margin-top: 12px;
    }
</style>
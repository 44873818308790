<template>
    <section class="record-body">
        <div class="margin-top-min margin-bottom-min" v-if="model.referrerURL" :title="model.referrerURL">
            From <a :href="model.referrerURL" target="_blank" class="record-url"><div class="record-url-text">{{ ellipseString( model.referrerURL, 60, true ) }}</div></a>
        </div>
        <span v-if="isFullInfoContext && formattedDate" class="record-date">
            {{ formattedDate }}
        </span>
        <section class="record-details-holder">
            <div><record-tags :obj="model" :isFullInfoContext="isFullInfoContext" /></div>
            <!--<score-dropdown v-if="!isMobile && isFullInfoContext && completeness" title="Completeness" :totalScorePercent="completeness.percentComplete" :subScores="completenessSubscores" @actionrequest="onCompletenessAction" direction="right" />-->
        </section>
        <record-email-log v-if="model.objectType=='emaillog'" :obj="model" :isFullInfoContext="isFullInfoContext" />
        <section class="record-description">
            <template v-if="cardDisplayType != 2 && description">
                <p v-html="highlightedDescription" ref="elDescription" />
            </template>
            <div class="flex-rows" style="justify-content:space-between;">
                <lob-tags :obj="model" />
                <record-views v-if="isFullInfoContext" :obj="model"></record-views>
            </div>
            <template v-if="isFullInfoContext">
                <record-body-extended :obj="model" :showEdit="recordContext == 'detailspage'" />
            </template>
        </section>
        <section v-if="isFullInfoContext" class="record-content record-content-view">
            <spin-loader :showMe="saveInProgress" /><div v-show="saveInProgress && saveInProgressTabs">Saving {{ saveInProgressTabs }}...</div>
                                                    <tabs v-show="!saveInProgress" v-model="selectedTab"
                                                          @click="userSelectedTab=selectedTab"
                                                          v-model:prevSelectedTab="prevSelectedTab"
                                                          v-model:shownTabs="shownTabs"
                                                          :ref="(el)=>setItemRef(el,'mainTabs')"
                                                          :class="tabsClass"
                                                          :enableMobileSlider="true"
                                                          v-observe-visibility="{ callback: visibilityChanged,  once: true}">
                                                        <tab title="Comment Entry" :show="showTab['comment']">
                                                            <spin-loader :showMe="!comment || saveInProgress" />
                                                            <comment-editor v-show="!saveInProgress"
                                                                            v-if="comment"
                                                                            v-model="comment"
                                                                            v-model:editing="isEditing.comment"
                                                                            v-model:errors="errors.comment"
                                                                            v-model:advanced="advancedMode"
                                                                            :editorID="editorID"
                                                                            :obj="model"
                                                                            :showSave="false"
                                                                            @editorloaded="scrollToEditor"
                                                                            @dirtied="onDirty('comment')"
                                                                            ref="commentEditor" />
                                                        </tab>
                                                        <tab :title="titlesLabel" :show="showTab['titles']" :badge="tabToBadge(null, editableTabCounts['titles'])">
                                                            <titles-editor v-if="showTab['titles']"
                                                                           v-model="titles"
                                                                           v-model:advanced="advancedMode"
                                                                           :obj="model"
                                                                           :showEdit="recordContext == 'detailspage'"
                                                                           :showHeader="false"
                                                                           :showSave="false" />
                                                        </tab>
                                                        <tab title="Comments" :show="showTab['comments']" :badge="tabToBadge('Comments')">
                                                            <record-events :obj="model" eventView="category" eventSource="comments" @editrequest="handleEditRequest" @eventsloaded="handleEventsLoaded({'tabName': 'Comments', count: $event})" :parentVisible="isVisible" :recordContext="recordContext" />
                                                        </tab>
                                                        <tab title="Acquisitions" :show="showTab['acquisitions']" :badge="tabToBadge('Acquisitions')">
                                                            <record-events :obj="model" eventSource="acquisitions" @eventsloaded="handleEventsLoaded({'tabName': 'Acquisitions', count: $event})" :parentVisible="isVisible" :recordContext="recordContext" />
                                                        </tab>
                                                        <tab title="Articles" :show="showTab['articles']" :badge="tabToBadge('Articles')">
                                                            <record-events :obj="model" eventSource="articles" @eventsloaded="handleEventsLoaded({'tabName': 'Articles', count: $event})" :parentVisible="isVisible" :recordContext="recordContext" />
                                                        </tab>
                                                        <tab title="M&A Interest" :show="showTab['mandaInterest']">
                                                            <spin-loader :showMe="saveInProgress" />
                                                            <mandaInterest-editor v-show="!saveInProgress"
                                                                                  v-if="showTab['mandaInterest']"
                                                                                  v-model="mandaInterest"
                                                                                  v-model:editing="isEditing.mandaInterest"
                                                                                  v-model:errors="errors.mandaInterest"
                                                                                  v-model:advanced="advancedMode"
                                                                                  :editorID="editorID"
                                                                                  :obj="model"
                                                                                  :showHeader="true"
                                                                                  :showSave="false"
                                                                                  @dirtied="onDirty('mandaInterest')"
                                                                                  ref="mandaInterestEditor" />
                                                        </tab>
                                                        <tab title="Financials" :show="showTab['financials']" :badge="tabToBadge(null, editableTabCounts['financials'])">
                                                            <spin-loader :showMe="saveInProgress" />
                                                            <financials-editor v-show="!saveInProgress"
                                                                               v-if="showTab['financials']"
                                                                               v-model="financials"
                                                                               v-model:editing="isEditing.financials"
                                                                               v-model:errors="errors.financials"
                                                                               v-model:advanced="advancedMode"
                                                                               :obj="model"
                                                                               :editorID="editorID"
                                                                               :showHeader="true"
                                                                               :showSave="false"
                                                                               @dirtied="onDirty('financials')"
                                                                               ref="financialsEditor" />
                                                        </tab>
                                                        <tab title="LOBs" :show="showTab['lobs']" :badge="tabToBadge(null, editableTabCounts['lobs'])">
                                                            <spin-loader :showMe="saveInProgress" />
                                                            <lobs-editor v-show="!saveInProgress"
                                                                         v-if="showTab['lobs']"
                                                                         v-model="lobs"
                                                                         v-model:editing="isEditing.lobs"
                                                                         v-model:errors="errors.lobs"
                                                                         v-model:advanced="advancedMode"
                                                                         :obj="model"
                                                                         :editorID="editorID"
                                                                         :showHeader="true"
                                                                         :showSave="false"
                                                                         @dirtied="onDirty('lobs')"
                                                                         ref="lobsEditor" />
                                                        </tab>
                                                        <tab title="Tags" :show="showTab['tags']" :badge="tabToBadge('Tags')">
                                                            <record-events :obj="model" eventSource="tagsflags" @eventsloaded="handleEventsLoaded({'tabName': 'Tags', count: $event})" :parentVisible="isVisible" :recordContext="recordContext" />
                                                        </tab>
                                                        <tab title="Emails" :show="showTab['emails']" :badge="tabToBadge('Emails')">
                                                            <record-events :obj="model" eventView="category" eventSource="emails" @eventsloaded="handleEventsLoaded({'tabName': 'Emails', count: $event})" :parentVisible="isVisible" :recordContext="recordContext" />
                                                        </tab>
                                                        <tab title="Events" :show="showTab['events']" :badge="tabToBadge('Events')">
                                                            <record-events :obj="model" eventSource="events" eventView="category" @eventsloaded="handleEventsLoaded({'tabName': 'Events', count: $event})" :parentVisible="isVisible" :recordContext="recordContext" />
                                                        </tab>
                                                        <tab title="Pages" :show="showTab['pages']" :badge="tabToBadge('Pages', sortedPages.length)">
                                                            <record-events v-if="sortedPages.length" :obj="model" eventSource="pages" :events="sortedPages" :parentVisible="isVisible" :recordContext="recordContext" />
                                                        </tab>
                                                        <tab title="Fingerprint" :show="showTab['fingerprint']">
                                                            <template v-if="model.fingerPrint">
                                                                Captured on {{ toShortDateString(model.fingerPrint.date) }}
                                                                <ul class="list-table style-alternate">
                                                                    <li :key="idx" v-for="(val, idx) in Object.keys(model.fingerPrint.values)">
                                                                        <span class="label">
                                                                            {{ val }}
                                                                        </span>
                                                                        <span class="data place-right" :title="model.fingerPrint.values[val]">
                                                                            {{ellipseString( model.fingerPrint.values[val] , 100, true)}}
                                                                        </span>
                                                                    </li>
                                                                </ul>
                                                            </template>
                                                        </tab>
                                                        <tab title="Other Sessions" :show="showTab['othersessions']">
                                                            under development
                                                        </tab>
                                                        <tab title="Location" :show="showTab['map']">
                                                            <geo-map :carddata="{ hits: [{ primaryAddress: model.primaryAddress }] }" @detailrequest="openGoogleMapsLink"></geo-map>
                                                        </tab>
                                                        <tab title="Subsets" :show="showTab['subsets']" :badge="tabToBadge('Subsets', sortedSubsets.length)">
                                                            <record-events v-if="sortedSubsets.length" :obj="model" eventSource="subsets" :events="sortedSubsets" :parentVisible="isVisible" :recordContext="recordContext" />
                                                        </tab>
                                                        <tab title="List Users" :show="showTab['listUsers']" :badge="tabToBadge('List Users', model.listUsers ? model.listUsers.length : 0)">
                                                            <titles-editor v-if="showTab['listUsers']"
                                                                           v-model="model.listUsers"
                                                                           :obj="model"
                                                                           :showAdvanced="false"
                                                                           :showAddData="false"
                                                                           :showHeader="false"
                                                                           :showSave="false" />
                                                        </tab>
                                                        <tab title="Filters" :show="showTab['filters']">
                                                            <applied-filters v-if="model.filters" :criteria="model.filters" :advanced="true" :readOnly="true" />
                                                        </tab>
                                                        <tab title="Entry Source" :show="showTab['entrysource']">
                                                            <div class="record-entry-details margin-vertical-small">
                                                                <div class="entry fullwidth">
                                                                    <span v-if="model.entryDate" class="entry-date"> Entered on {{ toShortDateString(model.entryDate) }} </span>
                                                                    <span v-if="model.entryUserName" class="entry-user"> by {{ model.entryUserName}} </span>
                                                                </div>
                                                                <div class="source fullwidth">
                                                                    <span v-if="model.sourceUserName" class="source-user"> Source {{ model.sourceUserName }} </span>
                                                                    <span v-if="model.entrySource" class="entry-source">
                                                                        - <a :href="model.entrySource.link" target="_blank">{{ model.entrySource.name }}</a>
                                                                    </span>
                                                                </div>
                                                                <div v-if="model.atTag" class="source fullwidth">
                                                                    <a href="javascript:void();" @click="handleFilterRequest({ 'filter': 'atTag', 'filterVal': model.atTag })"> {{ ('@' + model.atTag) }} </a>
                                                                </div>
                                                                <score-dropdown v-if="completeness" title="Completeness" :totalScorePercent="completeness.percentComplete" :subScores="completenessSubscores" @actionrequest="onCompletenessAction" class="margin-top-min" />
                                                            </div>
                                                        </tab>
                                                        <tab title="More Info" :show="showTab['moreInfo']">
                                                            <div class="grid wall fives margin-vertical">
                                                                <btn type="box" el="link" v-if="['person','company'].includes(objectType)" :href="'/Search/vue?buyerslistMemberShip=' + objectKey" target="_blank">
                                                                    On Buyers Lists
                                                                </btn>
                                                                <btn type="box" el="link" :href="'/Search/vue?listMemberShip=' + objectKey" target="_blank">
                                                                    List Membership
                                                                </btn>
                                                                <btn type="box" el="link" v-if="objectType == 'company'" :href="'/Company/hierarchy?id=' + objectKey" target="_blank">
                                                                    Company Hierarchy
                                                                </btn>
                                                                <btn type="box" el="link" v-if="objectType == 'company'" :href="'/Company/BuyerProfile?id=' + objectKey" target="_blank">
                                                                    Buyer Profile
                                                                </btn>
                                                                <btn type="box" el="link" :href="'/Search/vue?docType=acquisition&lobdistanceseed=' + objectKey" target="_blank">Comparables</btn>
                                                                <btn type="box" el="link" :href="'/Search/vue?listID=93&docType=Acquisition&sortby='+ objectKey" target="_blank">BNC Deals</btn>
                                                                <btn type="box" el="link" :href="'/Search/vue?docType=company&lobdistanceseed=' + objectKey" target="_blank">Competitors</btn>
                                                                <btn type="box" el="link" v-for="descent in mandasoftDescents" :key="descent.lobDescentID + '_' + descent.binID"
                                                                     :href="'/Search/vue?lobDescentID=' + descent.lobDescentID + '&binID=' + descent.binID">
                                                                    {{ descent.lobDescentName.replace('MandAsoft Segment:','') }} / {{ descent.binName }}
                                                                </btn>
                                                                <btn type="box" el="link" v-if="objectType == 'company'" :href="'https://mandasoft.com/Email/WDistanceEmail.aspx?&CompanyID?id=' + objectID" target="_blank">
                                                                    Weekly Deal Report Sample
                                                                </btn>
                                                                <btn type="box" el="link" v-if="objectType == 'company'" :href="'https://mandasoft.com/Email/DistanceEmail.aspx?&CompanyID?id=' + objectID + '&msrDateRange=msrDateRange'" target="_blank">
                                                                    Monthly Report Sample
                                                                </btn>
                                                                <btn type="box" el="link" :href="'/Search?geodistanceseed=' + objectKey + '&docType=' + objectType" target="_blank">Neighbors</btn>
                                                            </div>
                                                        </tab>
                                                        <tab title="Debug" :show="user && user.isAdmin">
                                                            <div :key="prop" v-for="prop in Object.keys(model)">
                                                                <p><strong>{{ prop }}:</strong> {{ model[prop] }}</p>
                                                            </div>
                                                        </tab>
                                                    </tabs>
            <div v-show="!saveInProgress" class="record-other-content">
                <ul v-if="objectType == 'company'" class="list-table style-alternate">
                    <li v-if="model.yearFounded">
                        <span class="label">Founded</span>
                        <span class="data">{{ model.yearFounded }}</span>
                    </li>
                    <li v-if="primaryContact">
                        <span class="label contact-title">{{ primaryContact.title }}</span>
                        <span class="data">
                            <a class="" target="_blank" :href="'/Person/Details/' + primaryContact.id">
                                <span class="contact-name align-right">{{ primaryContact.name }}</span>
                            </a>
                        </span>
                    </li>
                    <li v-if="lastAcquisition">
                        <span class="label">{{ toShortDateString(lastAcquisition.date) }}</span>
                        <span class="data align-right">
                            <a :href="'/Acquisition/details/' + lastAcquisition.id" target="_blank"> {{ lastAcquisitionName }} </a>
                        </span>
                    </li>

                </ul>
                <ul v-if="objectType == 'websession'" class="list-table style-alternate">
                    <li>
                        <span class="label">CMDM User</span>
                        <span class="place-right">
                            <record-owners :obj="model" :includedTypes="['user']" />
                        </span>
                    </li>
                    <li>
                        <span class="label">Web User</span>
                        <span class="place-right">
                            <record-owners :obj="model" :includedTypes="['webuser']" />
                        </span>
                    </li>
                </ul>
            </div>
        </section>
        <section v-else class="record-content record-content-view" v-observe-visibility="{ callback: visibilityChanged,  once: true}">
            <record-events :obj="model" eventSource="events" eventView="category" :parentVisible="isVisible" :renderLimit="1" :loadMore="false" :minified="true" :recordContext="recordContext" />
        </section>
    </section>
</template>
<script>
    import { ref, nextTick, computed, inject, onBeforeUpdate, onUpdated, onMounted, onBeforeUnmount } from 'vue'
    import { useStore } from 'vuex'
    import { useModelWrapper, useToast, useItemRefs } from '../../composables/ModelWrapper.js'
    import { getSearchTools } from '../../composables/SearchTools.js'
    import { getRecordTools } from '../../composables/RecordTools.js'
    import { getMqTools } from '../../composables/MqTools.js'
    import { getTextTools } from '../../composables/TextTools.js'

    import RecordOwners from './RecordOwners.vue'
    import Tab from '../ui/tabs/Tab.vue'
    import Tabs from '../ui/tabs/Tabs.vue'
    import ScoreDropdown from '../ui/progressbars/ScoreDropdown.vue'
    import RecordEvents from './RecordEvents.vue'
    import RecordTags from './RecordTags.vue'
    import RecordBodyExtended from './RecordBodyExtended.vue'
    import RecordViews from './RecordViews.vue'
    import RecordEmailLog from './RecordEmailLog.vue'
    import LobTags from '../ais/LobTags.vue'
    import TitlesEditor from '../Company/TitlesEditor.vue'
    import FinancialsEditor from '../Financials/FinancialsEditor.vue'
    import CommentEditor from '../Comment/CommentEditor.vue'
    import MandaInterestEditor from '../Comment/MandaInterestEditor.vue'
    import LobsEditor from '../ais/LobsEditor.vue'
    import GeoMap from '../Aggregates/GeoMap.vue'
    import AppliedFilters from '../Search/AppliedFilters.vue'

    import _ from 'lodash'
    import $ from 'jquery'

    export default {
        name: 'RecordBody',
        components: {
            RecordEvents, RecordTags, RecordViews, RecordBodyExtended, RecordEmailLog, Tabs, Tab, ScoreDropdown, LobTags,
            TitlesEditor, LobsEditor, FinancialsEditor, CommentEditor, MandaInterestEditor, GeoMap, AppliedFilters, RecordOwners
        },
        props: {
            'modelValue': { type: Object },            
            'editing': {
                type: Object,
                default: () => ({})
            },
            'dirty': {
                type: Object,
                default: () => ({})
            },
            'editorID': { type: String },
            'recordContext': { type: String, default: 'details' },
            'selTab': { type: String, default: '' },
            'cardDisplayType': { type: Number },
        },

        emits: ['filterrequest', 'editrequest', 'update:modelValue', 'update:editing'],
        setup(props, context) {
            // tools
            const toast = useToast()
            const store = useStore()
            const { itemRefs, setItemRef, itemRefsReset } = useItemRefs()

            onBeforeUpdate(() => {
                itemRefsReset()
            })
            onUpdated(() => {
                // console.log('onUpdated', itemRefs.value)
            })

            const emitter = inject("emitter")   // Inject event bus            

            const { objectURL, encodeOwnerKey } = getSearchTools()
            const { isMobile } = getMqTools()

            // models
            const model = computed({
                get: () => {
                    return props.modelValue ? props.modelValue : { id: 0, objectType: null, objectKey: null }
                },
                set(newVal) {
                    context.emit('update:modelValue', newVal)
                }
            })

            // data
            const isEditing = useModelWrapper(props, context.emit, 'editing')
            const isDirty = useModelWrapper(props, context.emit, 'dirty')

            const errors = ref({})
            const savingEdits = ref({})
            const comment = ref(null)
            const isVisible = ref(false)
            const advancedMode = ref(false)
            const selectedTab = ref('Entry Source')
            const prevSelectedTab = useModelWrapper(props, context.emit, 'selTab')
            const shownTabs = ref({})
            const tabCounts = ref({ 'Events': 0, 'Sessions': 0, 'Tags': 0, 'Comments': 0, 'Acquisitions': 0, 'Articles': 0 })
            const view = ref('events')
            const elDescription = ref(null)
            const userSelectedTab = ref('')

            // constants
            const editors = ['comment', 'mandaInterest', 'financials', 'lobs']

            //computed
            const { objectKey, objectType, objectID, formattedDate, isFullInfoContext, storeKey, highlightText } = getRecordTools(props, 'modelValue')
            const acqLog = computed(() => store.getters['acquisitions/acqLog'])
            const description = computed(() => {
                if (!model.value || objectType.value == 'person' || objectType.value == 'emaillog' || objectType.value == 'websession') return null
                return isFullInfoContext ? model.value.description : ellipseString(model.value.shortDescription ?? model.value.description, 500, true)
            })
            const highlightedDescription = computed(() => {
                //console.log('highlightedDescription', highlightText)
                if (model.value.html) {
                    return decorateHighlights({ body: model.value.html, tokens: highlightText ? highlightText.value: [] })
                }
                else
                    return decorateHighlights({ body: description.value, tokens: highlightText ? highlightText.value : [] })
            })
            const titlesLabel = computed(() => {
                return objectType.value == 'person' ? 'Titles' : 'People'
            })
            const titles = computed({
                get: () => {
                    var val;
                    if (props.modelValue) {
                        if (props.modelValue.objectType == 'company')
                            val = props.modelValue.people
                        else if (props.modelValue.objectType == 'person')
                            val = props.modelValue.titles
                    }
                    return val ? val : [];
                },
                set: (newVal) => {
                    if (props.modelValue) {
                        if (props.modelValue.objectType == 'company')
                            model.value.people = newVal
                        else if (props.modelValue.objectType == 'person')
                            model.value.titles = newVal
                    }
                }
            })
            const financials = computed({
                get: () => {
                    if (props.modelValue && props.modelValue.objectType == 'company' && props.modelValue.financials)
                        return props.modelValue.financials
                    return []
                },
                set: (newVal) => {
                    if (props.modelValue) {
                        if (props.modelValue.objectType == 'company') {
                            model.value.financials = newVal
                        }
                    }
                }
            })
            const lobs = computed({
                get: () => {
                    if (props.modelValue && ['company', 'acquisition'].includes(props.modelValue.objectType) && props.modelValue.lobs)
                        return props.modelValue.lobs
                    return []
                },
                set: (newVal) => {
                    if (['company', 'acquisition'].includes(props.modelValue.objectType))
                        model.value.lobs = newVal
                }
            })
            const mandaInterest = computed({
                get: () => {
                    if (props.modelValue && ['company', 'person'].includes(props.modelValue.objectType) && props.modelValue.mandaInterest)
                        return props.modelValue.mandaInterest
                    return {}
                },
                set: (newVal) => {
                    if (['company', 'person'].includes(props.modelValue.objectType))
                        model.value.mandaInterest = newVal
                }
            })

            const dirtyTabs = computed(() => {
                var tabs = []
                if (isDirty.value) {
                    Object.keys(isDirty.value).forEach((key) => {
                        if (isDirty.value[key])
                            tabs.push(key)
                    })
                }
                return tabs.sort() // sort puts comment editor first
            })
            const tabErrors = computed(() => {
                var errorCount = 0;
                var errorMessages = [];
                var errorDic = {};

                dirtyTabs.value.forEach((type) => {
                    if (errors.value[type] && errors.value[type].errorCount)
                        errorCount += errors.value.errorCount;

                    if (errors.value[type] && errors.value[type].errorMessages.length) {
                        errorMessages = errorMessages.concat(errors.value[type].errorMessages);
                        errorDic[type] = errors.value.errorMessages;
                    }
                })

                return { errorCount: errorCount, errorMessages: errorMessages, errorDictionary: errorDic }
            })
            const sortedPages = computed(() => {
                var pages = model.value.pages ? _.reverse(_.cloneDeep(model.value.pages)) : []
                _.each(pages, function (p) {
                    p.id = 0;
                    p.description = p.pageURL;
                    p.linkURL = p.pageURL;
                })

                return pages;
            })
            const sortedSubsets = computed(() => model.value.subsets ? _.reverse(_.cloneDeep(model.value.subsets)) : [])

            const showTab = computed(() => {
                var tabs = {}
                tabs['comment'] = isFullInfoContext.value && !!isEditing.value.comment
                tabs['mandaInterest'] = isFullInfoContext.value && ['company', 'person'].includes(objectType.value)
                tabs['financials'] = isFullInfoContext.value && objectType.value == 'company'
                tabs['lobs'] = isFullInfoContext.value && ['company', 'acquisition'].includes(objectType.value)
                tabs['titles'] = isFullInfoContext.value && ['company', 'person'].includes(objectType.value) && titles.value.length > 0
                tabs['events'] = !!tabCounts.value.Events
                tabs['sessions'] = !!tabCounts.value.Sessions
                tabs['tags'] = !!tabCounts.value.Tags
                tabs['comments'] = !!tabCounts.value.Comments
                tabs['emails'] = ['company', 'person', 'emaillog'].includes(objectType.value) && !!tabCounts.value.Emails
                tabs['acquisitions'] = objectType.value == 'company' && !!tabCounts.value.Acquisitions
                tabs['articles'] = objectType.value == 'acquisition' && !!tabCounts.value.Articles
                tabs['entrysource'] = objectType.value != 'websession' && model.value && (!!model.value.entryDate || !!model.value.entryUserName || !!model.value.sourceUserName || !!model.value.entrySource || !!model.value.atTag)
                tabs['moreInfo'] = props.recordContext == 'detailspage'

                tabs['map'] = (props.recordContext == 'detailspage' || objectType.value == 'websession') && model.value.primaryAddress && !!model.value.primaryAddress.location
                tabs['listUsers'] = objectType.value == 'list' && !!model.value.listUsers
                tabs['subsets'] = objectType.value == 'list' && sortedSubsets.value.length
                tabs['filters'] = objectType.value == 'query' && !!model.value.filters

                tabs['pages'] = objectType.value == 'websession' && sortedPages.value.length
                tabs['fingerprint'] = objectType.value == 'websession' && !!model.value.fingerPrint
                tabs['othersessions'] = objectType.value == 'websession' && false

                return tabs
            })
            const visibleTabCount = computed(() => Object.keys(showTab.value).map((key) => showTab.value[key]).filter((t) => t).length)
            const tabsClass = computed(() => {
                if (isMobile.value) {
                    return visibleTabCount.value == 2 ? 'has-two-tabs' : 'has-' + visibleTabCount.value + '-tabs'
                }
                return visibleTabCount.value > 3 ? 'space-between' : null
            })
            const activeEditors = computed(() => {
                return isEditing.value ? Object.keys(isEditing.value).filter(function (k) { return isEditing.value[k] }) : []
            })
            const saveInProgressTabs = computed(() => {
                var names = "";
                     _.each(savingEdits.value, function (val, key) {
                         if (val && val.status == 'inprogress') {
                             var tabname = key.charAt(0).toUpperCase() + key.slice(1);
                             names = names ? (names + ', ' + tabname) : tabname;
                         }
                     });
                return names;
            })
            const saveInProgress = computed(() => {
                return Object.keys(savingEdits.value).filter((key) => savingEdits.value[key].status == 'inprogress').length > 0
            })

            const user = computed(() => store.state && store.state.userInfo && store.state.userInfo.user ? store.state.userInfo.user.userInfo : null)

            // methods
            const { decorateHighlights, ellipseString } = getTextTools()
            const resetEditingState = () => {
                comment.value = null
                editors.forEach((key) => {
                    isEditing.value[key] = false
                })
                nextTick(() => { isDirty.value = {} })
            }
            const saveEdits = (req) => {
                _.noop('saveEdits', req)
                if (saveInProgress.value) return

                if (!dirtyTabs.value.length) {
                    toast.add({ severity: 'error', summary: 'Nothing to save', detail: '', life: 5000 })
                    return
                }

                if (tabErrors.value.errorCount == 0 && tabErrors.value.errorMessages.length == 0) {
                    savingEdits.value = {}
                    dirtyTabs.value.forEach((tab) => {
                        savingEdits.value[tab] = { status: 'inprogress', message: null }
                    })

                    emitter.emit('saveEditor', {
                        editorID: props.editorID,
                        editorTargets: dirtyTabs.value,
                        complete: 'saveRecordBodyComplete',
                        origin: 'record-body'
                    })
                }
                else
                    toast.add({ severity: 'error', summary: 'Invalid data', detail: tabErrors.value.errorMessages.join(' \r\n'), life: 5000 })
            }
            const cancelEdits = () => {
                if (saveInProgress.value) return

                savingEdits.value = {}
                emitter.emit('rollbackModel', { editorID: props.editorID, origin: 'record-body' })
                resetEditingState()
                nextTick(() => { selectSmartTab() })
            }

            const selectDefaultTab = () => {
               selectedTab.value = findDefaultTab()
            }
            const selectSmartTab = () => {
                //stay in the current tab unless it doesn't exist anymore
                //in which case go to the previous tab unless it doesn't exist anymore
                if (!shownTabs.value[selectedTab.value]) {
                    if (shownTabs.value[prevSelectedTab.value])
                        selectedTab.value = prevSelectedTab.value
                    else
                        selectDefaultTab()
                }
            }
            const handleEventsLoaded = (ev) => {
                //update the counts after events load
                tabCounts.value[ev.tabName] = ev.count

                //then decide if we need to select the tab. Comment Entry and whatever the user clicked on should always stick
                if (selectedTab.value != 'Comment Entry') {
                    nextTick(() => {
                        if (shownTabs.value[userSelectedTab.value])
                            selectedTab.value = userSelectedTab.value
                        else if (shownTabs.value[prevSelectedTab.value])
                            selectedTab.value = prevSelectedTab.value
                        else
                            selectDefaultTab()
                    })
                }
            }
            const findDefaultTab = () => {
                var newTab;
                if (showTab.value['comment'])
                    newTab = 'Comment Entry'
                else if (showTab.value['titles'])
                    newTab = titlesLabel.value
                else if (showTab.value['comments'])
                    newTab = 'Comments'
                else if (showTab.value['pages'])
                    newTab = 'Pages'
                else if (showTab.value['filters'])
                    newTab = 'Filters'
                else if (showTab.value['subsets'])
                    newTab = 'Subsets'
                else if (showTab.value['events'])
                    newTab = 'Events'
                else if (showTab.value['emails'])
                    newTab = 'Emails'
                else
                    newTab = 'Entry Source'
                return newTab
            }
            const openEditMode = (req) => {
                //only these 2 are allowed to edit
                if (['sidebar', 'detailspage'].includes(props.recordContext)) {
                    if (req && req.objectType == 'comment') {
                        isEditing.value['comment'] = true
                        selectedTab.value = 'Comment Entry'
                        loadComment(req)
                    }
                }
            }
            const loadComment = (req) => {
                comment.value = null
                store.dispatch('comments/fetchComment', { 'id': req ? req.id : 0, 'objectKey': model.value.objectKey })
                    .then((result) => {
                        comment.value = result
                        selectedTab.value = 'Comment Entry'
                        scrollToEditor(req.action)

                    }).catch(error => {
                        toast.add({ severity: 'error', summary: 'Error fetching Comment Template', detail: error, life: 3000 })
                        cancelEdits()
                    })
            }
            const scrollToEditor = (action) => {
                nextTick(() => {
                    if (itemRefs.value.mainTabs) {
                        if (props.recordContext == 'sidebar')
                            $('.sidebar-body').animate({
                                scrollTop: $(itemRefs.value.mainTabs.$el).height() - ($('.sidebar-header').outerHeight(true) * 2)
                            }, 0);
                        else
                            $('.box-content-viewer').animate({
                                scrollTop: $(itemRefs.value.mainTabs.$el).height() - 150
                            }, 0);
                    }
                    if (action) {
                        //console.log('emitting setFlag', { editorID: props.editorID, action: action })
                        setTimeout(() => { emitter.emit('setFlag', { editorID: props.editorID, action: action }) }, 500)
                    }
                })
            }
            const completeSave = (req) => {
                if (!req)
                    req = {}

                if (!saveInProgress.value) {
                    var hold = Object.keys(savingEdits.value).filter((key) => savingEdits.value[key].status == 'onhold')
                    if (!hold.length) {
                        var success = Object.keys(savingEdits.value).filter((key) => savingEdits.value[key].status == 'success' && savingEdits.value[key].changed)
                        var fail = Object.keys(savingEdits.value).filter((key) => savingEdits.value[key].status == 'fail')
                        if (fail.length == 0) {
                            setTimeout(() => { store.dispatch('objectCache/fetchObject', { objectKey: objectKey.value, fullFetch: true }) }, 1000)

                            if (!req.suppressToastMessages) toast.add({ severity: 'success', summary: 'Save complete', detail: success.length + ' tabs saved', life: 3000 })
                        }
                        else {
                            var errors = fail.map((stat) => savingEdits.value[stat].message)
                            toast.add({ severity: 'error', summary: 'Save complete with errors', detail: errors.join(', '), life: 3000 })
                        }


                        savingEdits.value = {}
                        resetEditingState()
                        nextTick(() => {
                            selectSmartTab()
                        });
                    }
                }
            }

            // event bus   
            const editCommentRecordBodyHandler = (req) => {
                if (props.editorID && req.editorID == props.editorID)
                    openEditMode(req.obj)
            }
            const toggleCommentRecordBodyHandler = (req) => {
                if (props.editorID && req.editorID == props.editorID) {
                    if (isEditing.value.comment)
                        selectedTab.value = 'Comment Entry'
                    else
                        openEditMode({ id: 0, objectType: 'comment' })
                }
            }
            const cancelEditsRecordBodyHandler = (req) => {
                if (props.editorID && req.editorID == props.editorID)
                    cancelEdits()
            }
            const saveEditsRecordBodyHandler = (req) => {
                if (props.editorID && req.editorID == props.editorID)
                    saveEdits(req)
            }
            const saveRecordBodyCompleteHandler = (req) => {
                if (props.editorID && req.editorID == props.editorID) {
                    //console.log('saveRecordBodySuccess', req)
                    savingEdits.value[req.editorName] = req.result
                    completeSave(req)
                }
            }
            const visibilityChanged = (newVisiblity, entry) => {
                isVisible.value = newVisiblity
                _.noop(entry)
            }

            const tabToBadge = (tab, count) => {
                if (!count)
                    count = tabCounts.value[tab]

                return (!!count && count > 0 ? (count < 99 ? count.toString() : '99+') : '0')
            }

            onMounted(() => {
                selectedTab.value = (!['detailspage'].includes(props.recordContext) ? 'Entry Source' :
                    (props.modelValue && props.modelValue.objectType == 'person' ? 'Titles' : 'People'))

                emitter.on("editCommentRecordBody", editCommentRecordBodyHandler)
                emitter.on("toggleCommentRecordBody", toggleCommentRecordBodyHandler)
                emitter.on("cancelEditsRecordBody", cancelEditsRecordBodyHandler)
                emitter.on("saveEditsRecordBody", saveEditsRecordBodyHandler)
                emitter.on("saveRecordBodyComplete", saveRecordBodyCompleteHandler)

                if (elDescription.value) {
                    const descLinks = elDescription.value.querySelectorAll('a');
                    descLinks.forEach(link => link.addEventListener('click', (ev) => {
                        if ($(ev.target.attr('href')))
                            window.open($(ev.target.attr('href')))
                    }))

                    const atTagLinks = elDescription.value.querySelectorAll('.cmdmAtTag');
                    atTagLinks.forEach(link => link.addEventListener('click', (ev) => {
                        var url = objectURL({ id: $(ev.target).data('tagid'), objectTypeID: $(ev.target).data('tagtype') })
                        if (url)
                            window.open(url)
                    }))

                    const hashTagLinks = elDescription.value.querySelectorAll('.cmdmHashTag');
                    hashTagLinks.forEach(link => link.addEventListener('click', (ev) => {
                        //console.log('hashTagLinks-click', ev.target)

                        if ($(ev.target).data('tagname')) {
                            var tags = { name: $(ev.target).data('tagname') }
                            if ($(ev.target).data('tagvalue'))
                                tags.value = $(ev.target).data('tagvalue')
                            var url = '/search/vue/?tags=' + encodeURIComponent(JSON.stringify(tags))
                            window.open(url)
                        }
                    }))
                }

                selectDefaultTab()
            })

            onBeforeUnmount(() => {
                emitter.off("editCommentRecordBody", editCommentRecordBodyHandler)
                emitter.off("toggleCommentRecordBody", toggleCommentRecordBodyHandler)
                emitter.off("cancelEditsRecordBody", cancelEditsRecordBodyHandler)
                emitter.off("saveEditsRecordBody", saveEditsRecordBodyHandler)
                emitter.off("saveRecordBodyComplete", saveRecordBodyCompleteHandler)
            })


            return {
                // models
                model, isEditing, user,
                // data
                isDirty, errors, savingEdits, comment, isVisible, advancedMode, editors, selectedTab, prevSelectedTab, shownTabs,
                tabCounts, view, elDescription, userSelectedTab,
                // computed
                objectKey, objectType, objectID, storeKey, titles, financials, lobs, mandaInterest, dirtyTabs,
                acqLog, description, highlightedDescription,
                tabErrors, showTab, isMobile, visibleTabCount, tabsClass,
                titlesLabel, activeEditors, saveInProgress, saveInProgressTabs, sortedPages, sortedSubsets, formattedDate,
                // methods
                resetEditingState, saveEdits, cancelEdits, selectSmartTab, findDefaultTab, scrollToEditor,
                handleEventsLoaded, openEditMode, selectDefaultTab, completeSave, objectURL, encodeOwnerKey,
                visibilityChanged, decorateHighlights, ellipseString, tabToBadge,
                // tools
                emitter, itemRefs, setItemRef, itemRefsReset, highlightText, isFullInfoContext
            }
        },
        watch: {
            'isEditing': {
                handler(newValue) {
                    console.log(newValue)
                }, deep: true
            },
        },        
        computed: {            
            primaryAddressLink() {
                var result = null
                if (this.model && this.model.primaryAddress) {
                    var qs = []
                    if (this.model.primaryAddress.address1) qs.push(this.model.primaryAddress.address1)
                    if (this.model.primaryAddress.address2) qs.push(this.model.primaryAddress.address2)
                    if (this.model.primaryAddress.city) qs.push(this.model.primaryAddress.city)
                    if (this.model.primaryAddress.stateProv) qs.push(this.model.primaryAddress.stateProv)
                    if (this.model.primaryAddress.postalCode) qs.push(this.model.primaryAddress.postalCode)
                    if (this.model.primaryAddress.country) qs.push(this.model.primaryAddress.country)
                    result = (qs.length > 0 ? qs.join('+') : null)
                }
                return result
            },
            primaryAddress() {
                var address = null
                if (this.model && this.model.primaryAddress) {
                    if (this.model.primaryAddress.city || this.model.primaryAddress.stateProv || this.model.primaryAddress.country) {
                        address = (this.model.primaryAddress.city ? (this.model.primaryAddress.city.trim() + ',') : '') +
                            (this.model.primaryAddress.stateProv ? ' ' + this.model.primaryAddress.stateProv.trim() : '') +
                            (this.model.primaryAddress.country && this.model.primaryAddress.country != 'USA' ? ' ' + this.model.primaryAddress.country.trim() : '')
                    }
                }
                return address
            },
            primaryPostalCode() {
                var postalCode = null
                if (this.model && this.model.primaryAddress) {
                    if (this.model.primaryAddress.country == 'USA' && this.model.primaryAddress.postalCode) {
                        postalCode = this.model.primaryAddress.postalCode
                        if (postalCode.includes('-'))
                            postalCode = postalCode.substr(0, postalCode.indexOf('-'))
                    }
                }
                return postalCode
            },
            normalizedName() {
                return this.model && this.model.normalizedName ? encodeURIComponent(this.model.normalizedName) : null
            },
            completeness() {
                return this.model && this.model.completeness ? this.model.completeness : (this.model && this.model.acqCompleteness ? this.model.acqCompleteness : null)
            },
            completenessSubscores() {
                var subScores = []
                if (this.completeness) {
                    if (this.completeness.cLevelFactor != undefined) {
                        subScores.push({ title: 'C-Level', factor: this.completeness.cLevelFactor, action: 'edit#personInfo' })
                    }
                    if (this.completeness.verifiedLOBFactor != undefined) {
                        subScores.push({ title: 'LOBs', factor: this.completeness.verifiedLOBFactor, action: 'edit#lobInfo' })
                    }
                    if (this.completeness.foundedFactor != undefined) {
                        subScores.push({ title: 'Founded', factor: this.completeness.foundedFactor, action: 'edit#miscInfo' })
                    }
                    if (this.completeness.logoFactor != undefined) {
                        subScores.push({ title: 'Logo', factor: this.completeness.logoFactor, action: 'edit#logoInfo' })
                    }
                    if (this.completeness.descriptionFactor != undefined) {
                        subScores.push({
                            title: this.objectType == 'acquisition' ? 'Synopsis' : 'Description', factor: this.completeness.descriptionFactor, action: 'edit#descriptionInfo'
                        })
                    }
                    if (this.completeness.financialFactor != undefined) {
                        subScores.push({ title: (this.objectType == 'acquisition' ? 'Deal ' : '') + 'Financials', factor: this.completeness.financialFactor, action: 'edit#financialInfo' })
                    }
                    if (this.completeness.emailFactor != undefined) {
                        subScores.push({ title: 'Valid Email', factor: this.completeness.emailFactor, action: 'edit#personInfo' })
                    }
                    if (this.completeness.mandaInterestFactor != undefined) {
                        subScores.push({ title: 'M&A Interest', factor: this.completeness.mandaInterestFactor, action: 'edit#mandaInterestInfo' })
                    }
                    if (this.completeness.sellerDescriptionFactor != undefined) {
                        subScores.push({ title: 'Seller Description', factor: this.completeness.sellerDescriptionFactor, action: 'edit#description' })
                    }
                    if (this.completeness.sellerFinancialFactor != undefined) {
                        subScores.push({ title: 'Seller Financials', factor: this.completeness.sellerFinancialFactor, action: 'edit#seller' })
                    }
                    if (this.completeness.sellerParentsFactor != undefined) {
                        subScores.push({ title: 'Seller Parents', factor: this.completeness.sellerParentsFactor, action: 'edit#seller' })
                    }
                    if (this.completeness.buyerDescriptionFactor != undefined) {
                        subScores.push({ title: 'Buyer Description', factor: this.completeness.buyerDescriptionFactor, action: 'edit#buyer' })
                    }
                    if (this.completeness.buyerParentsFactor != undefined) {
                        subScores.push({ title: 'Buyer Parents', factor: this.completeness.buyerParentsFactor, action: 'edit#buyer' })
                    }
                    if (this.completeness.buyerParentsFactor != undefined) {
                        subScores.push({ title: 'Buyer Parents', factor: this.completeness.buyerParentsFactor, action: 'edit#buyer' })
                    }
                    if (this.completeness.tombstoneFactor != undefined) {
                        subScores.push({ title: 'Tombstone', factor: this.completeness.tombstoneFactor, action: 'edit#tombstone' })
                    }
                    if (this.completeness.lobFactor != undefined) {
                        subScores.push({ title: 'LOBs', factor: this.completeness.lobFactor, action: 'edit#lobInfo' })
                    }
                }
                return subScores
            },
            editLink() {
                return this.objectType && this.model.id ? '/' + this.objectType + '/Edit/' + this.model.id : null
            },
            primaryContact() {
                if (this.model && this.model.people) {
                    return this.model.people.find(peop => peop.primaryContact)
                }
                return null
            },
            lastAcquisition() {
                if (this.acqLog[this.storeKey] && this.acqLog[this.storeKey].events)
                    return this.acqLog[this.storeKey].events[0]
                return null
            },
            lastAcquisitionName() {
                return this.lastAcquisition ? this.ellipseString(this.lastAcquisition.name, 150, true) : null
            },
           
            editableTabCounts: function () {
                var tabs = {};
                tabs['financials'] = this.objectType == 'company' && this.model.financials ? this.model.financials.length : 0;
                tabs['lobs'] = ['company', 'acquisition'].includes(this.objectType) && this.model.lobs ? this.model.lobs.length : 0;
                tabs['titles'] = this.titles ? _.filter(this.titles, function (t) { return t.active }).length : 0

                return tabs;
            },
            recordURL() {
                return (this.model ? this.model.url : null)
            },
            urlClass() {
                return this.model && this.model.primaryMXdomain && this.model.primaryMXdomain.failureCount > 0 ? 'type-error' : ''
            },
            mandasoftDescents() {
                var descents = []
                if (this.model && this.model.lobs) {
                    this.model.lobs.forEach((lob) => {
                        if (lob.descentMappings) {
                            lob.descentMappings.forEach((descent) => {
                                var exists = descents.find((d) => { return d.lobDescentID == descent.lobDescentID && d.binID == descent.binID })
                                if (!exists)
                                    descents.push(descent)
                            })
                        }
                    })
                }
                return descents
            }
        },
        methods: {
            onDirty: function (type) {
                this.isDirty[type] = true;
            },
            editorHasUnsavedChanges() {
                return this.dirtyTabs.length > 0;
            },
            onCompletenessAction(action) {
                if (action.startsWith('edit#')) {
                    window.open(this.editLink + action.substr(4), '_blank')
                }
            },
            handleEditRequest(obj) {
                if (['sidebar', 'detailspage'].includes(this.recordContext))
                    this.openEditMode(obj);
                else
                    this.$emit('editrequest', obj)
            },
            openGoogleMapsLink() {
                window.open('http://maps.google.com/maps?q=' + this.primaryAddressLink, '_blank')
            },
        }
    }
</script>
<style scoped lang="scss">
    .btn-group {margin:1px 5px;}
    .btn-xs {margin: 1px 3px;}

    span.text-danger{color:darkred}
    span.text-danger {
        color: darkred
    }
    span.text-warning {
        color: gold
    }
    span.text-success {
        color: forestgreen
    }
    span.text-info {
        color: dodgerblue
    }

    a:any-link.text-danger i::before { color: darkred;}
  
    a:any-link.text-warning i::before {
        color: gold;
    }

   
    a:any-link.text-success i::before {
        color: forestgreen;
    }

    a:any-link.text-info i::before {
        color: dodgerblue;
    }

</style>

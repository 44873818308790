<template>
    <li class="menu-item" :title="title"
        :class="{ 'has-submenu': hasSlotSubMenu, 'has-expansion': hasContentSlot, 'has-actions': hasActionsSlot, 'load-toggle': isLoadToggle, 'is-open': isOpen, 'active': active  }">
        <router-link v-if="isRouterLink" :to="routerLinkTo">
            <i v-if="icon" class="menu-icon" :class="iconType + ' fa-' + icon"></i>
            <span v-if="labelSummary" class="menu-label"><span class="first-letter">{{ labelSummary.substr(0,1) }}</span><span class="remaining-letters">{{ labelSummary.substr(1) }}</span></span>
        </router-link>
        <a v-else :href="href" @click="handleClick" :target="target">
            <i v-if="icon" class="menu-icon" :class="iconType + ' fa-' + icon"></i>
            <span v-if="labelSummary" class="menu-label">
                <span class="first-letter">{{ labelSummary.substr(0,1) }}</span><span class="remaining-letters">{{ labelSummary.substr(1) }}</span>
            </span>
            <span v-if="hasActionsSlot" class="menu-actions">
                <slot name="actions" />
            </span>
        </a>
        <ul v-if="hasSlotSubMenu" class="submenu">
            <template v-if="children">
                <menu-item v-for="menu in visibleChildren"
                           :key="menu.action"
                           :href="menu.href"
                           :target="menu.target ?? '_self'"
                           :isRouterLink="menu.isRouterLink"
                           :label="menu.label"
                           :icon="menu.icon"
                           :title="menu.label"
                           :level="nextLevel"
                           :initIsOpen="menu.active"
                           :active="menu.active"
                           :children="menu.children"
                           :maxChildren="menu.children"
                           :shortenLabel="shortenLabel"/>
                <menu-item v-if="showLess"
                           label="-show fewer..."
                           :isLoadToggle="true"
                           title="Show fewer dashboards"
                           :level="nextLevel"
                           @loadmore="loadMore(false)"
                           :shortenLabel="shortenLabel"/>
                <menu-item v-if="showMore"
                           label="+show more..."
                           :isLoadToggle="true"
                           title="Show more dashboards"
                           :level="nextLevel"
                           @loadmore="loadMore(true)"
                           :shortenLabel="shortenLabel"/>

            </template>
            <slot name="children" />
        </ul>
        <div v-if="hasContentSlot" class="menu-expansion">
            <slot />
        </div>
    </li>
</template>

<script>
    import { ref, Comment, computed, onMounted } from 'vue'
    import { isOpenSetup } from '../../../composables/isOpen.js'

    import { RouterLink } from 'vue-router'

    export default {
        //Create functionality for emitting event instead of having a href
        name: 'MenuItem',
        components: { RouterLink },        
        props: {
            href: { type: String },
            target: { type: String, default: "_self" },
            label: { type: String },
            title: { type: String },
            isRouterLink: { type: Boolean, default: false },
            children: { type: Array },
            iconType: { type: String, default: 'far' },
            icon: { type: String },
            level: { type: Number, default: 0 },
            initIsOpen: { type: Boolean, default: false },
            isLoadToggle: { type: Boolean, default: false },
            active: { type: Boolean, default: false },
            maxChildren: { type: Number, default: 5 },
            shortenLabel: { type: Number, default: 0 },
        },
        emits: ['loadmore'],
        setup(props, { slots, emit }) {
            // tools
            const { isOpen, toggleIsOpen } = isOpenSetup()
            // data
            const childrenVisibleCount = ref(props.maxChildren ?? 5)

            // computed
            const nextLevel = computed(() => props.level + 1)
            const compHref = computed(() => props.href ? props.href : 'javascript:void();')
            const routerLinkTo = computed(() => props.isRouterLink && props.href ? JSON.parse(props.href) : null)
            const hasContentSlot = computed(() => slots.default && slots.default().findIndex(o => o.type !== Comment) !== -1)
            const hasChildrenSlot = computed(() => slots.children && slots.children().findIndex(o => o.type !== Comment) !== -1)
            const hasActionsSlot = computed(() => slots.actions && slots.actions().findIndex(o => o.type !== Comment) !== -1)
            const hasSlotSubMenu = computed(() => props.children || hasChildrenSlot.value)
            const hasAnySlot = computed(() => hasContentSlot.value || hasChildrenSlot.value || hasActionsSlot.value || hasSlotSubMenu.value)
            const visibleChildren = computed(() => props.children ? props.children.slice(0, childrenVisibleCount.value) : [])
            const showMore = computed(() => props.children ? childrenVisibleCount.value < props.children.length : false)
            const showLess = computed(() => props.children ? childrenVisibleCount.value > 5  : false)
            const labelSummary = computed(() => props.label && props.shortenLabel && props.label.length > props.shortenLabel ? props.label.substring(0, props.shortenLabel) + '...' : props.label );

            // methods
            const handleClick = () => {
                //console.log('menu-handleclick', ev)
                if (props.isLoadToggle) {
                    emit('loadmore', props.label != 'Show Less...')
                }
                else if (hasAnySlot.value && (props.href == undefined || props.href == 'javascript:void(0);')) {
                    toggleIsOpen()
                }               
            }
            const loadMore = (more = true) => {
                if (more)
                    childrenVisibleCount.value = childrenVisibleCount.value + 99
                else
                    childrenVisibleCount.value = childrenVisibleCount.value - 99
            }



            onMounted(() => {
                isOpen.value = props.initIsOpen
            })

            return {
                // data
                isOpen, childrenVisibleCount,
                // computed
                nextLevel, compHref, routerLinkTo, visibleChildren, showMore, showLess,
                hasContentSlot, hasChildrenSlot, hasActionsSlot, hasSlotSubMenu, labelSummary,
                // methods
                toggleIsOpen, handleClick, loadMore
            }
        }
    }
</script>

<style scoped>

</style>
import { computed, inject } from 'vue'
import { getTextTools } from './TextTools.js'
import _ from 'lodash'

export function getRecordTools(props, objectProp = 'obj', objectValue = null) {
    // tools
    const highlightText = inject('highlightText')
    const { fromCamelCase, decorateHighlights, ellipseString, toShortDateString, toShortTimeString, toEventDate, toEventDateMobile, sinceDate } = getTextTools()

    // const
    const formatDateMap = { company: 'none', person: 'none', acquisition: 'shortDate', event: 'eventDate' }
    const recordContexts = ['pin-card', 'sidebar', 'details', 'table', 'brands', 'detailspage', 'geoMap']
    const cardDisplayTypeRecordContextMap = { 2: 'table', 3: 'brands', 4: 'details', 7: 'geoMap' }
    const fullInfoContexts = ['sidebar', 'detailspage']

    // computed
    const theObj = computed(() => (objectProp ? props[objectProp] : (objectValue ? objectValue.value : null)))
    const objectID = computed(() => theObj.value ? theObj.value.id : null)
    const objectName = computed(() => (theObj.value && theObj.value.name ? theObj.value.name : "No Name"))
    const objectType = computed(() => theObj.value ? theObj.value.objectType : null)
    const objectLobs = computed(() => theObj.value ? theObj.value.lobs : null)
    const objectKey = computed(() => (theObj.value && theObj.value.objectKey ? theObj.value.objectKey : (objectID.value && objectID.value ? objectID.value + '::' + objectID.value : null)))
    const storeKey = computed(() => objectKey.value.replace('::', "__"))
    const recordTitle = computed(() => {
        if (theObj.value) {
            var result = objectName.value
            switch (objectType.value) {
                case 'company':
                    result = objectName.value + (theObj.value.lastFinancial && theObj.value.lastFinancial.tickerSymbol ? " [" + theObj.value.lastFinancial.tickerSymbol + "]" : "")
                    break
                case 'websession':
                    result = theObj.value.landing ? 'Landing Page' : 'Web Visit' + (theObj.value.serverURL ? ": " + theObj.value.serverURL + ""  : '')
                    break
                case 'event':
                    result = objectName.value
                    break
            }
            return result
        }
        return null
    })
    const getTitleURL = ((obj) => {
        var url = "/NotFound";
        if (obj) {
            switch (obj.objectType) {
                case 'event':
                    if (obj.ownerType == 'query' && obj.ownerID)
                        url = '/search/vue/?queryID=' + obj.ownerID
                    else if (obj.id)
                        url = '/eventlog/details/' + obj.id
                    break;

                case 'comment':
                    if (obj.owners && obj.owners.length && obj.id) {
                        let compOwner = _.find(obj.owners, function (o) { return o.ownerTypeID == 1 });
                        if (compOwner)
                            url = '/company/detailspage/' + compOwner.ownerID + '?commentID=' + obj.id
                        else {
                            let personOwner = _.find(obj.owners, function (o) { return o.ownerTypeID == 3 });
                            if (personOwner)
                                url = '/person/detailspage/' + personOwner.ownerID + '?commentID=' + obj.id
                        }
                    }
                    break;

                case 'emaillog':
                    if (obj.owners && obj.owners.length && obj.id) {
                        let compOwner = _.find(obj.owners, function (o) { return o.ownerTypeID == 1 });
                        if (compOwner)
                            url = '/company/detailspage/' + compOwner.ownerID + '?emailLogID=' + obj.id
                        else {
                            let personOwner = _.find(obj.owners, function (o) { return o.ownerTypeID == 3 });
                            if (personOwner)
                                url = '/person/detailspage/' + personOwner.ownerID + '?emailLogID=' + obj.id
                        }
                    }
                    break;

                case 'query':
                    if (obj.id)
                        url = '/search?queryID=' + obj.id;
                    else if (obj.key)
                        url = '/search?' + obj.key.replace('query::', 'queryID=');
                    break;

                case 'dashboardcard':
                    url = '/search?dashboardid=' + obj.ownerID + '&cardid=' + obj.id;
                    break;

                default:
                    if (obj.id)
                        url = '/' + obj.objectType + '/detailspage/' + obj.id
                    break;
            }
        }
        return url;
    })

    const titleURL = computed(() => {
        return getTitleURL(theObj.value)
    })
    const editURL = computed(() => {
        return objectType.value != 'query' ? ('/' + objectType.value + '/edit/'  + objectID.value) : ('/Search?queryID=' + objectID.value)
    })



    const shortHighlightedTitle = computed(() => decorateHighlights({ body: ellipseString(recordTitle.value, 80, true), tokens: highlightText ? highlightText.value : [], highlightClass: 'text-highlight' }))

    const highlightedTitle = computed(() => decorateHighlights({ body: recordTitle.value, tokens: highlightText ? highlightText.value : [], highlightClass: 'text-highlight' }))

    const formattedDate = computed(() => {
        var fDate = theObj.value ? theObj.value.date : null
       // var fDate = theObj.value ? (objectType.value && objectType.value == 'event' && theObj.value.name != 'Reminder Flag' ? theObj.value.entryDate : theObj.value.date) : null
        if (!fDate) return null
        switch (formatDateMap[objectType.value]) {
            case 'none':
                fDate = null
                break
            case 'shortDate':
                fDate = toShortDateString(fDate)
                break
            case 'eventDate':
                fDate = toEventDate(fDate)
                break
            case 'sinceDate':
                fDate = sinceDate(fDate)
                break
            case 'shortTime':
                fDate = toShortTimeString(fDate)
                break
            default:
                fDate = toShortDateString(fDate) + (toShortTimeString(fDate) ? (', ' + toShortTimeString(fDate)) : '')
                break;
        }
        return fDate
    })
    const formattedDateMobile = computed(() => {
        var fDate = theObj.value ? theObj.value.date : null
       // var fDate = theObj.value ? (objectType.value && objectType.value == 'event' && theObj.value.name != 'Reminder Flag' ? theObj.value.entryDate : theObj.value.date) : null
        if (!fDate) return null
        switch (formatDateMap[objectType.value]) {
            case 'eventDate':
                fDate = toEventDateMobile(fDate)
                break
            default:
                fDate = null
                break;
        }
        return fDate
    })
    const eventType = computed(() => {
        if (!theObj.value) return null

        var result = objectType.value

        if (result) {
            switch (result) {
                case 'websession':
                    result = (theObj.value.landing ? 'LandingPage' : 'WebSession');
                    break;
                default:
                    result = result.charAt(0).toUpperCase() + result.slice(1);
            }
        }
        return result
    })
    const isFullInfoContext = computed(() => fullInfoContexts.includes(props.recordContext ))

    return {
        // const
        formatDateMap, recordContexts, cardDisplayTypeRecordContextMap, fullInfoContexts,
        // computed
        theObj, objectID, objectName, objectType, objectLobs, objectKey, storeKey, recordTitle, titleURL, editURL, shortHighlightedTitle, highlightedTitle,
        eventType, formattedDate, formattedDateMobile, isFullInfoContext, highlightText,
        // methods
        fromCamelCase, getTitleURL
    }
}

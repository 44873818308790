<template>
    <div v-if="card" class="dashboard is-active">
        <section class="card" :class="[cardClass, { 'is-open': expand }]">
            <header v-if="showHeader" class="card-header">
                <h3 class="card-title" :title="card && card.name && card.name.length > cardName.length ? card.name : ''">
                    <a v-if="listURL" :href="listURL" target="_blank">{{ cardName }}</a>
                    <span v-if="!listURL">{{ cardName }}</span>
                    <span v-show="deltaCount" class="marker-new deltaCount">{{ deltaCount }}</span>
                </h3>
                <div class="card-count">
                    <span v-if="!suppressCount" class="queryCount">{{ loading ? '?' : total }}</span>
                    <spin-loader :loading="card.loading"></spin-loader>
                </div>
                <span v-if="!suppressOptions" class="cardOptions-container group dashOptions-container">
                    <span v-if="card.lockDateFilter && card.dateRangeKey" class="card-is-locked" :title="dateRangeDesc ? dateRangeDesc : card.dateRangeKey">
                        <i class="fas fa-calendar"></i>
                    </span>
                    <div v-if="card" class="cardOptions">
                        <card-options :dashboard="ownerDashboard" :card="card" />
                    </div>
                </span>
            </header>
            <article class="card-body" :class="cardBodyClass">
                <template v-if="model && Object.keys(model).length > 0 && !model.error">
                    <template v-if="[2,3,4].includes(card.cardDisplayType)">
                        <records-list :carddata="model" :cardDisplayType="card.cardDisplayType" @editrequest="$emit('editrequest', $event)" @detailrequest="openRecordDetails" />
                    </template>
                    <template v-else-if="card.cardDisplayType == 1 && (model.counts || model.percentiles)">
                        <aggregate-stats :carddata="model" :aggPanelName="card.aggregatePanel" @applyfilter="handleApplyFilter"></aggregate-stats>
                    </template>
                    <template v-else-if="card.cardDisplayType == 5">
                        <aggregate-graph :carddata="model" :aggName="card.graphAggregate"></aggregate-graph>
                    </template>
                    <template v-else-if="card.cardDisplayType == 6">
                        <tag-cloud :carddata="model" @applyfilter="handleApplyFilter"></tag-cloud>
                    </template>
                    <template v-else-if="card.cardDisplayType == 7">
                        <geo-map :carddata="model" @detailrequest="openRecordDetails"></geo-map>
                    </template>
                </template>
                <template v-else-if="!loading">
                    <div v-if="model.error" class="margin-top-small align-center">
                        Error loading Data: {{ model.error }}
                    </div>
                    <div v-else class="margin-top-small align-center">
                        No Data
                    </div>
                    <div>&nbsp;</div>
                </template>
            </article>
            <footer class="card-footer">
                <div v-if="card.cardDisplayType == 4" @click="toggleCardStack" class="card-stack-toggle"><span class="stack-count">{{ hitCount }}</span></div>
            </footer>
        </section>
    </div>
</template>

<script>
    import { ref, computed } from 'vue'
    import { useStore } from 'vuex'
    import { useModelWrapper } from '../../composables/ModelWrapper.js'
    import { isOpenSetup } from '../../composables/isOpen.js'
    import { mapState } from 'vuex'

    import SpinLoader from '../baseComponents/SpinLoader.vue'
    import CardOptions from './CardOptions.vue'
    import AggregateStats from '../Aggregates/AggregateStats.vue'
    import AggregateGraph from '../Aggregates/AggregateGraph.vue'
    import TagCloud from '../Aggregates/TagCloud.vue'
    import GeoMap from '../Aggregates/GeoMap.vue'
    import RecordsList from '../Records/RecordsList.vue'
    
    export default {
        name: 'DashboardCard',
        props: {
            'id': { type: Number },
            'card': { type: Object },
            'modelValue': { type: Object },
            'dashboard': { type: Object },
            'dashboards': { type: Object },
            'suppressHeader': { type: Boolean },
            'suppressOptions': { type: Boolean },
            'suppressCount': { type: Boolean },
            'canCollapse': { type: Boolean },
            'suppressListURL': { type: Boolean }
        },
        components: {
            SpinLoader, CardOptions, AggregateStats, AggregateGraph, TagCloud, GeoMap, RecordsList
        },
        emits: ['detailrequest', 'update:modelValue'],
        setup(props, { emit }) {
            const store = useStore()
            // Models
            const model = useModelWrapper(props, emit, 'modelValue')
            
            // Data
            const expand = ref(false)
            const { isOpen } = isOpenSetup()
            const dateRanges = computed(() => store.getters["search/dateRangeMap"])

            // Computed
            const deltaCount = computed(() => model.value && model.value.deltaCount ? model.value.deltaCount : 0)
            const total = computed(() => model.value && model.value.total ? model.value.total : 0)
            const dateRangeDesc = computed(() => props.card && props.card.dateRangeKey ? (dateRanges.value[props.card.dateRangeKey] ? dateRanges.value[props.card.dateRangeKey].description : 'Custom Range') : null)

            return { model, expand, isOpen, deltaCount, total, dateRangeDesc}
        },
        provide() {
            return {
                query: computed(() => this.model && this.model.query ? { id: this.model.query.id } : null)
            }
        },
        computed: {
            ...mapState({
                user: state => state.userInfo.user
            }),
            cardName() {
                return this.card && this.card.name ? this.ellipseString(this.card.name, 35, true) : 'No name';
            },
            ownerDashboard() {
                if (this.dashboard) {
                    if (this.dashboard.id == this.card.ownerID)
                        return this.dashboard;
                }

                if (this.dashboards) {
                    var dashes = this.dashboards.filter(d => { return d.id == this.card.ownerID })
                    if (dashes.length > 0)
                        return dashes[0];
                }


                return null;
            },
            openClass() {
                return this.isOpen ? 'collapse show ' : ' '
            },
            loading() {
                if (this.card && this.card.loaded != undefined)
                    return !this.card.loaded
                return false
            },
            expandTargetID() { return this.card.id + '-card-content' },
            showHeader() { return !this.suppressHeader },
            collapseCard() { return this.canCollapse && !this.suppressHeader ? this.canCollapse : false },
            isTableflex() { return this.card && this.card.docType == 'websession' },
            listURL() {
                var listURL = ''
                if (this.card && this.card.sourceObjectType && !this.suppressListURL) {
                    listURL = ''
                    switch (this.card.sourceObjectType) {
                        case 'query':
                            listURL = '/Search/Vue?cardid=' + this.card.id + '&dashboardid=' + +  this.card.ownerID
                            break
                        case "company":
                        case "person":
                        case "acquisition":
                        case "article":
                        case "comment":
                        case "dashboard":
                        case "dashboardgroup":
                        case "list":
                        case "buyerslist":
                        case "hopperlist":
                        case "user":
                        case "lobdescent":
                            listURL = '/' + this.card.sourceObjectType + '/details/' + this.card.sourceID
                            break
                    }
                }
                return listURL
            },
            cardClass() {
                var cardClass = ''
                switch (this.card.cardDisplayType) {
                    case 1:
                        cardClass = 'aggregate';
                        break
                    case 2:
                        cardClass = 'stack is-open';
                        break;
                    case 4:
                        cardClass = 'stack' + (this.isOpen ? ' is-open' : '')
                        break
                }
                return cardClass
            },
            hitCount() {
                if (this.model && this.model.hits) {
                    if (this.model.limit) 
                        return Math.min(this.model.hits.length, this.model.limit)
                    else
                        return this.model.hits.length
                    }
                return 0
            },
            cardBodyClass() {
                var cardBodyClass = this.openClass
                switch (this.card.cardDisplayType) {
                    case 2: // table
                        //cardBodyClass += 'no-frame'
                        cardBodyClass += 'objectListTable no-padding-top'
                        break
                    case 4: // details
                        cardBodyClass += 'objectListTable no-padding-top'
                        break
                }
                return cardBodyClass
            }
        },
        methods: {
            fetchCardData() {
                if (this.card) {
                    this.$store.dispatch('dashboards/fetchCardData', { 'dashboardID': this.card.ownerID, 'cardIDs': [this.card.id] })
                        .then((result) => {
                            console.log('fetchCardData', result)
                        })
                        .catch(error => {
                            this.$toast.add({ severity: 'error', summary: 'Error loading card data', detail: error, life: 3000 })
                        })
                }
            },
            openRecordDetails(request) {
                //overwrite these fields on the request if we have card data
                if (this.card) request.cardID = this.card.id
                if (this.card && this.card.query) request.queryID = this.card.query.id
                
                this.$emit('detailrequest', request)
            },
            getHit(listIndex) { // obsolete
                if (this.model.hits && listIndex >= 0 && listIndex < this.model.hits.length) {
                    return { 'obj': this.model.hits[listIndex], 'listIndex': listIndex, 'cardID': this.card.id }
                }
                else
                    return null
            },
            handleApplyFilter({ filter, filterVal }) {
                if (this.listURL)
                    window.open(this.listURL + '&filtername=' + filter + '&filterval=' + filterVal, '_blank')
            },
            toggleCardStack() {
                this.expand = !this.expand;
            }
        }
    }
</script>

<style lang="scss">
    
</style>
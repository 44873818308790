<template>
    <nav class="main-menu">
        <div class="main-menu-toggle" :class="{ 'is-open': show }" @click="toggleMainMenu">
            <span class="bars">
                <span></span>
                <span></span>
                <span></span>
                <span></span>
            </span>
        </div>
        <ul :class="{ 'is-open': show }">
            <template v-if="isMobile">
                <menu-item href="javascript:void(0);" icon="far fa-bookmark" label="Bookmarks" :level="nextLevel">
                    <template #children>
                        <bookmarks-menu :level="nextLevel + 1" />
                    </template>
                </menu-item>
                <menu-item href="javascript:void(0);" icon="far fa-user" label="User Info" :level="nextLevel">
                    <template #children>
                        <user-menu :level="nextLevel + 1" />
                    </template>
                </menu-item>
            </template>
            <menu-item v-for="menu in userLayout.navigation"
                       :key="menu.action"
                       :href="menu.href"
                       :target="menu.openNewTab ? '_blank' : ''"
                       :isRouterLink="menu.isRouterLink"
                       :label="menu.label"
                       :icon="menu.icon"
                       :title="menu.label"
                       :level="nextLevel"
                       :initIsOpen="menu.active"
                       :active="menu.active"
                       :children="menu.children"
                       :shortenLabel="20"
                       />

        </ul>
    </nav>
</template>


<style>
</style>

<script>
    import { ref, computed, inject, onMounted, onBeforeUnmount } from 'vue'
    import { useStore } from 'vuex'
    import { getLocalStorageTools } from '../../composables/LocalStorageTools.js'
    import { getMqTools } from '../../composables/MqTools.js'

    import MenuItem from '../ui/menus/MenuItem.vue'
    import UserMenu from './UserMenu.vue'
    import BookmarksMenu from '../Bookmarks/BookmarksMenu.vue'

    export default {
        name: 'MainMenu',
        components: {
            MenuItem, UserMenu, BookmarksMenu
        },
        setup() {
            // tools
            const store = useStore()
            const emitter = inject("emitter")   // Inject event bus 
            const { isMobile } = getMqTools()
            const { fetchLocal, saveLocal } = getLocalStorageTools()
            const storageKey = 'mainmenu_toggleState'

            // data
            const nextLevel = ref(1)
            const show = ref(false)
            // computed
            const userLayout = computed(() => store.state.userInfo.userLayout)
            // methods
            const toggleMainMenu = () => {
                show.value = !show.value
                saveLocal(storageKey, show.value)
            }
            const onRouteChange = (newRoute) => {
                console.log('onRouteChange', newRoute, show.value, isMobile.value)
                if (isMobile.value && show.value)
                    show.value = false
            }

            // lifecycle
            onMounted(() => {
                nextLevel.value = 1
                show.value = fetchLocal(storageKey, false)
                emitter.on("routeChange", onRouteChange)
            })
            onBeforeUnmount(() => {
                emitter.off("routeChange", onRouteChange)
            })

            return {
                // tools
                emitter,
                // data
                nextLevel, show,
                // computed
                userLayout, isMobile,
                // methods
                toggleMainMenu, fetchLocal, saveLocal, onRouteChange
            }
        }
    }
    
</script>
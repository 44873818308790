<template>
    <section v-if="cardDisplayType == 3" class="grid images 1 objectListTable">
        <record-image v-for="(hit, listIndex) in cardDataHits"
                      :key="hit.objectKey"
                      :obj="hit"
                      :listIndex="listIndex"
                      @detailrequest="$emit('detailrequest', $event)" />
    </section>
    <ul v-else class="list-records details-view">
        <li v-for="(hit, listIndex) in cardDataHits" :key="hit.objectKey" :class="(listIndex == 0 ? 'first-query-item' : '') + ' record'">
            <record v-model="cardDataHits[listIndex]"
                    :parentContext="parentContext"
                    :listIndex="listIndex"
                    :cardDisplayType="cardDisplayType"
                    :recordContext="recordContext"
                    :highlightText="highlightText"
                    @detailrequest="$emit('detailrequest', $event)"
                    @editrequest="$emit('detailrequest', { 'obj': hit, 'listIndex': listIndex, 'editRequest' : $event })" />
        </li>
    </ul>
</template>

<script>
    import Record from '../Records/Record.vue'
    import RecordImage from '../Records/RecordImage.vue'

    import { computed } from 'vue'
    import { getRecordTools } from '../../composables/RecordTools.js'



    export default {
        name: 'RecordsList',
        components: {
            Record, RecordImage, 
        },
        props: {
            'carddata': { type: Object },
            'cardDisplayType': { type: Number },
            'highlightText': { type: Array },
            'parentContext': { type: String, default: '' },
        },
        emits: ['detailrequest', 'update:modelValue'],
        setup(props) {

            const { cardDisplayTypeRecordContextMap } = getRecordTools(props)
            const recordContext = computed(() => (cardDisplayTypeRecordContextMap[props.cardDisplayType] ?? 'table'))
            const cardDataHits = computed(() => {
                if (props.carddata && props.carddata.hits) {
                    if (props.carddata.limit)
                        return props.carddata.hits.slice(0, props.carddata.limit);
                    else
                        return props.carddata.hits
                }
                else return []
            })

            return { cardDisplayTypeRecordContextMap, recordContext, cardDataHits }
        }
    }
</script>

<style scoped lang="scss">
</style>
<template>
    <template v-if="model">
        <spin-loader :showMe="showSpinLoader" />
        <div v-show="!showSpinLoader">
            <div v-if="advancedMode" class="comment-editor-head flex-rows no-responsive margin-bottom-small">
                <div v-if="userIsOwner" class="agent flex-rows no-responsive">
                    <label>Agent:</label>
                    <div class="input-select">
                        <user-select :multiple="false" v-model="userAgent" :inpopup="true" />
                    </div>
                </div>
                <div class="date-picker">
                    <input :disabled="!userIsOwner" class="comment-date-picker" type="date" :max="luxonDate()" :value="luxonDate(model.date)" @change="dateChange($event)" />
                </div>
                <div v-if="userIsOwner" class="privacy flex-rows no-responsive place-right">
                    <label>Privacy:</label>
                    <button class="button-secondary small" value="model.isPublic" @click="togglePublic"><i :class="'far fa-' + (model.isPublic ? 'unlock' : 'lock')"></i> {{ model.isPublic ? 'Public' : 'Private' }}</button>
                </div>
                <button v-if="user.isAdmin" class="button-error small" @click="deleteComment"><i :class="'far fa-trash'"></i> Delete</button>
            </div>
            <div class="comment-editor-head">
                <div v-if="!advancedMode" class="margin-bottom-small">
                    <input :disabled="!userIsOwner" class="comment-date-picker" type="date" :max="luxonDate()" :value="luxonDate(model.date)" @change="dateChange($event)" />
                </div>
                <div class="input-select grid-6-12" v-if="contacts.length > 0">
                    <smart-select :disabled="!userIsOwner" :inpopup="true" :multiple="true" @change="ownerChange" :choices="contacts" :valuefield="'ownerID'" :labelfield="'ownerName'" :value="selectedContacts"></smart-select>
                </div>
            </div>

            <div :class="'editor tinymce comment ' + isMiniView ? 'mini box border' : '' ">
                <editor api-key="rv4i55wf01hrogvwbp2lr4k3iwi0qhrznwe550tb9a1qbftn"
                        ref="theEditor"
                        :init="tinyConfig" />
                <div class="editor-footer">
                    <ul class="menu-horizontal icons commentActions">
                        <li v-for="flag in availableFlags" :key="flag.key" :class="commentFlags[flag.key] ? 'is-active' : ''">
                            <a :ref="flag.key" href="javascript:;" :title="commentFlags[flag.key] ? flag.titleunset : flag.titleset" @click="editorSetFlag(flag.key)">
                                <i :class="flag.icon"></i>
                                <span class="count"> {{ ownerFlags[flag.key].length }} </span>
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
            <i v-show="model.dirty" class="fa fa-asterisk"></i>
            <div class="comment-editor-footer frame-bottom-small flex-rows nowrap align-top">
                <div class="flex-rows" style="min-height: 38px;">
                    <ul class="list-tags tag-container">
                        <li v-for="(tag, idx) in model.tags" :key="idx">
                            <tag :type="tag.name == 'atTag' ? 'at' : 'hashtag'" action="remove">
                                <template v-if="tag.name == 'atTag'">
                                    {{tag.values[0] && tag.values[0].value ? tag.values[0].value : ''}}
                                </template>
                                <template v-else>
                                    {{tag.name}}
                                    <template v-if="tag.values && tag.values.length && tag.values[0].value">
                                        :
                                        <template v-for="(value, idx) in tag.values" :key="value">
                                            {{ (idx > 0 ? ', ' : '') + (value.ownerID ? '@'+value.value : value.value)}}
                                        </template>
                                    </template>
                                </template>
                            </tag>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="previousActions margin-bottom" v-if="userFlags.length > 0">
                <h4>Recent History</h4>
                <ul class="list-vertical">
                    <li v-for="(flag, idx) in userFlags.slice(0,5)" class="" :key="idx">
                        <span>
                            <a :href="'/Comment/Edit/'+ flag.commentID" target="_blank">
                                <i :class="'far ' + flagsByCategory[flag.biCategory + (flag.value ? flag.value : '')].fa_icon"></i>
                            </a>
                            <template v-if="flag.biCategory == 128 && flag.reminderDate">
                                {{ toLuxonDate(flag.reminderDate).diffNow('days').days > 0 ? flagsByCategory[flag.biCategory].nextReminderFlag : flagsByCategory[flag.biCategory].prevReminderFlag }} {{ luxonDate(flag.reminderDate) }}
                            </template>
                            <template v-else>
                                {{ flagsByCategory[flag.biCategory + (flag.value ? flag.value : '')].prevFlag }} {{ luxonDate(flag.date) }}
                            </template>
                        </span>
                    </li>

                    <li v-if="userFlags.length > 5" class="">...</li>
                </ul>
            </div>
            <checkbox v-model="advancedMode" label="Advanced Mode" :showAsSwitch="true" />
            <div v-show="advancedMode" class="margin-top-xsmall">
                <checkbox v-model="openNewTagInEditor" label="Open newly added Tags on Editor" />
                <checkbox v-model="useFullTagEditor" label="Use Full Editor for @Tags" />
            </div>
        </div>
        <modal ref="shareTargetModal" title="Share" :showOverflow="true">
            <template #body>
                <user-select v-model="shareTarget" :multiple="false" :inpopup="true" />
                <button @click="shareSetFlag()" class="button-primary place-right margin-top-small">Add Flag</button>
            </template>
        </modal>
        <modal ref="reminderDateModal" title="Flag">
            <template #body>
                <input ref="reminderDate" class="comment-date-picker" style="height: 100%;" type="date" :min="luxonDate()" :value="luxonDate()">
                <button @click="reminderSetFlag()" class="button-primary place-right margin-top-small">Add Flag</button>
            </template>
        </modal>
        <modal ref="tagEditorModal" :title="selectedTag.isNew ? 'Add ' + selectedTag.tagType : 'Edit ' + selectedTag.tagType" :draggable="true" :showOverflow="true">
            <template #body>
                <tag-editor v-model="selectedTag.tag" :fullEditor="useFullTagEditor || selectedTag.tagType == 'HashTag'" :initialFocus="selectedTag.tagType" :inpopup="true" @tagsaved="saveTag($event)"></tag-editor>
            </template>
        </modal>
        <modal ref="storeModal" title="Stored Comments">
            <template #body>
                <store-elements elementType="comment" @selected="loadComment($event)" @close="$refs.storeModal.closeTheModal()"></store-elements>
            </template>
        </modal>
        <modal v-model="commentTypeModalOpen" title="Save as...">
            <template #body>
                <div class="content grid-12-12">
                    <template v-for="type in filteredCommentTypes" :key="type.id">
                        <div class="margin-top-small">
                            <btn size="fullwidth" @click="type.id == 4 && !hasDeal ? setCommentType(type.id) : setCommentTypeAndSave(type.id)"><i :class="type.icon"></i>{{ type.id == 4 && !hasDeal ? type.entry + '...' : type.entry }}</btn>
                        </div>
                    </template>
                    <section v-if="model.commentType == '4' && !hasDeal" class="box options">
                        <header class="box-header">Buyer's List</header>
                        <section class="box-body">
                            <div class="input-group nowrap">
                                <buyers-list-select :inpopup="true" v-model="dealNote.selectedBuyersList" />
                            </div>
                        </section>
                        <header class="box-header margin-top">Interest</header>
                        <section class="box-body">
                            <div class="input-group nowrap">
                                <div class="checkbox">
                                    <radio-list v-model="dealNote.interest" :radioValues="dealNote.interestValues" :labels="dealNote.interestLabels"></radio-list>
                                </div>
                            </div>
                        </section>
                        <template v-if="[1,2,4,5].includes(dealNote.interest)">
                            <header class="box-header margin-top">Disposition</header>
                            <section class="box-body">
                                <fieldset>
                                    <input class="style-alternate form-control fullwidth" required="" v-model="dealNote.disposition" type="text" autocomplete="off">
                                </fieldset>
                            </section>
                        </template>
                        <div class="margin-top">
                            <btn size="fullwidth" @click="setCommentTypeAndSave(4)">Save</btn>
                        </div>
                    </section>
                </div>
            </template>
        </modal>
    </template>
</template>

<script>
    import { ref, nextTick, computed, inject } from 'vue'
    import { mapGetters, useStore } from 'vuex'
    import { useModelWrapper, useToast } from '../../composables/ModelWrapper.js'
    import appMaps from '../../mixins/appMaps.js'
    import axios from 'axios'
    import Editor from '@tinymce/tinymce-vue'
    import $ from 'jquery'
    import _ from 'lodash'

    import UserSelect from '../baseComponents/UserSelect.vue'
    import SmartSelect from '../baseComponents/SmartSelect.vue'
    import tagHelper from '../lib/tagHelper.js'
    import storeHelper from '../lib/storeHelper.js'
    import TagEditor from './TagEditor.vue'
    import Tag from '../ui/tags/Tag.vue'
    import StoreElements from '../baseComponents/StoreElements.vue'
    import BuyersListSelect from '../baseComponents/BuyersListSelect.vue'
    import { DateTime } from 'luxon'
    import RadioList from '../ui/forms/RadioList.vue'
    import { editorProps, editorEmits } from '../../composables/Editors.js'


    const blankSelectedTag = { tag: null, node: null, isNew: true, tagType:'HashTag' }
    const blankDealNote = {
        selectedBuyersList: null,
        interest: null,
        disposition: null,
        interestValues: [1, 2, 3, 4, 5, 7],
        interestLabels: ['Interested', 'Not Interested', 'No Response', 'Following Up', 'Interested [1st Wave]', 'No Response [Historical]']
    }

    export default {
        name: 'CommentEditor',
        components: {
            UserSelect,
            SmartSelect,
            Editor,
            TagEditor,
            Tag,
            StoreElements,
            BuyersListSelect,
            RadioList
        },
        emits: [...editorEmits(), 'update:modelValue', 'editorloaded'],
        mixins: [appMaps],
        props: {
            ...editorProps(),
            commentID: { type: Number, default: 0 },
            modelValue: { type: Object },
            editorID: { type: String },
        },
        setup: function (props, context) {
            // tools
            const store = useStore()
            const toast = useToast()
            const emitter = inject("emitter")   // Inject event bus 
            const nextConfirm = inject('nextConfirm')

            // models
            const getModel = () => {
                var initialModel = props.modelValue ? _.cloneDeep(props.modelValue) : null;
                if (initialModel) {
                    if (typeof initialModel.description == 'undefined') initialModel.description = ''
                    if (typeof initialModel.html == 'undefined') initialModel.html = ''
                    if (typeof initialModel.tags == 'undefined') initialModel.tags = []
                    if (typeof initialModel.ownerUserFlags == 'undefined') initialModel.ownerUserFlags = []

                    if (initialModel.id == 0) initialModel.commentType = 0
                }
                return initialModel
            }


            const model = ref(getModel())
            const baseModel = _.cloneDeep(model.value)
            const oldModel = _.cloneDeep(model.value)
            const advancedMode = useModelWrapper(props, context.emit, 'advanced')
            const errState = useModelWrapper(props, context.emit, 'errors')
            const isEditing = useModelWrapper(props, context.emit, 'editing')
            const editor = ref({});
            const userAgent = ref(null);
            const initialEntryUserID = ref(model.value.entryUserID);

            // const
            var commentTypeIDs = ""
            store.state.comments.commentTypes.forEach((commentType) => {
                commentTypeIDs += "commentType" + commentType.id + " ";
            })

            // data
            const showSpinLoader = ref(false)
            const saveModalOpen = ref(false)
            const commentTypeModalOpen = ref(false)
            const dealNote = ref(_.cloneDeep(blankDealNote))
            const saveRequest = ref(null)
            const storeKey = ref(DateTime.now() + 'comm')

            // computed
            const isNew = computed(() => {
                return !model.value.id
            })
            const user = computed(() => store.state.userInfo.user.userInfo)
            const userIsOwner = computed(() => {
                return  user.value.isAdmin || isNew.value || initialEntryUserID.value == user.value.id;
            })

            const companyOwners = computed(() => {
                return model.value && model.value.owners ? model.value.owners.filter(owner => owner.ownerTypeID != 3) : []
            })
            const contacts = computed(() => {
                return (props.obj && props.obj.people ? props.obj.people.map(contact => ({
                    ownerActive: true,
                    ownerID: contact.id,
                    ownerName: contact.name,
                    ownerTypeID: 3
                })) : [])
            })
            const dirtyProps = computed(() => {
                return model.value ? {
                    entryUserID: model.value.entryUserID, date: model.value.date, isPublic: model.value.isPublic,
                    owners: model.value.owners, tags: model.value.tags, html: model.value.html, description: model.value.description,
                    commentType: model.value.commentType
                } : {}
            })

            // methods
            const storeComment = () => {
                if (model.value.html && model.value.description) {
                    var owners = model.value.owners ? model.value.owners.filter(function (owner) { return owner.ownerTypeID == 1 && owner.ownerActive; }).map(function (owner) { return owner.ownerName; }) : [];
                    if (owners.length == 0)
                        owners = model.value.owners ? model.value.owners.filter(function (owner) { return owner.ownerActive; }).map(function (owner) { return owner.ownerName; }) : [];

                    var sComps = owners.join(',');

                    var commentToStore = {
                        title: sComps,
                        text: model.value.description.length > 100 ? model.value.description.slice(0, 100) + '...' : model.value.description,
                        values:
                        {
                            html: model.value.html,
                            description: model.value.description
                        }
                    };


                    try {
                        storeHelper.storeUtils.storeElement(commentToStore, storeKey.value);
                        console.log('comment stored', storeKey.value);
                    }
                    catch (ex) {
                        toast.add({ severity: 'error', summary: 'Error storing Comment locally', detail: ex, life: 5000 })
                    }
                }
            }

            const saveChanges = (suppressToastMessages) => {
                return new Promise((resolve, reject) => {
                    if (userIsOwner.value) {
                        if (!model.value.dirty) {
                         //   if (!suppressToastMessages)
                           //     toast.add({ severity: 'info', summary: 'Comment not changed', detail: 'not saving', life: 3000 })
                            resolve({ changed: false })
                            return
                        }

                        if (errState.value && errState.value.errorCount) {
                            if (!suppressToastMessages)
                                toast.add({ severity: 'error', summary: 'Comment not valid', detail: errState.value.errorMessages.join(', '), life: 3000 })
                            reject('Comment not valid: ' + errState.value.errorCount + ' errors')
                            return
                        }
                        else {
                            // don't save if we have to show the modal
                            if (!showCommentTypeModal()) {
                                console.log('saving and parsing one last time...')
                                showSpinLoader.value = true
                                parseEditorTags.value(false, true, true)

                                store.dispatch('comments/saveComment', model.value)
                                    .then(() => {
                                        showSpinLoader.value = false
                                        model.value = _.cloneDeep(model.value)

                                        if (!suppressToastMessages)
                                            toast.add({ severity: 'info', summary: 'Comment Saved', life: 5000 })

                                        resolve({ changed: true })
                                    }).catch(error => {
                                        showSpinLoader.value = false
                                        if (!suppressToastMessages)
                                            toast.add({ severity: 'error', summary: 'Error saving Comment', detail: error.message, life: 3000 })
                                        reject('Error saving Comment: ' + error.message)
                                    })
                            }
                            else
                                resolve({ status: 'gettingcommentType' })
                        }
                    }
                    else
                    {
                        if (!suppressToastMessages)
                            toast.add({ severity: 'error', summary: 'You cannot save this comment', detail: 'not saving', life: 3000 })

                        resolve({ changed: false })
                        return
                    }
                })
            }

            const deleteComment = () => {
                nextConfirm("Delete", "Delete comment? This action cannot be undone.", "warning").then((res) => {
                    if (res) {
                        showSpinLoader.value = true
                        store.dispatch('comments/deleteComment', model.value)
                            .then(() => {
                                showSpinLoader.value = false
                                toast.add({ severity: 'error', summary: 'Comment Deleted', life: 5000 })

                                emitter.emit('cancelEditsRecordBody', { editorID: props.editorID, origin: 'comment-editor' })
                            }).catch(error => {
                                showSpinLoader.value = false
                                toast.add({ severity: 'error', summary: 'Error deleting Comment', detail: error.message, life: 3000 })
                            })
                    }
                });
            }

            const showCommentTypeModal = () => {
                if (model.value.dirty && isNew.value && model.value.commentType == 0) {
                    dealNote.value = _.cloneDeep(blankDealNote);
                    commentTypeModalOpen.value = true
                    return true
                }
                return false
            }
            const setCommentType = (id) => {
                model.value.commentType = id
            }
            const saveChangesAndEmit = () => {
                var editorName = 'comment'
                var objKey = props.obj ? props.obj.objectKey : null;
                saveChanges(saveRequest.value.suppressToastMessages).then((result) => {
                    console.log(result)
                    if (result.status == 'gettingcommentType')
                        emitter.emit(saveRequest.value.complete, { objectKey: objKey, editorName: editorName, editorID: props.editorID, result: { status: 'onhold', message: null, changed: false }, origin: editorName + '-editor' })
                    else
                        emitter.emit(saveRequest.value.complete, { objectKey: objKey, editorName: editorName, editorID: props.editorID, result: { status: 'success', message: null, changed: result.changed }, origin: editorName + '-editor' })
                }).catch(error => {
                    emitter.emit(saveRequest.value.complete, { objectKey: objKey, editorName: editorName, editorID: props.editorID, result: { status: 'fail', message: error }, origin: editorName + '-editor' })
                })
            }
            const setCommentTypeAndSave = (id) => {
                model.value.commentType = id
                commentTypeModalOpen.value = false
                saveChangesAndEmit()
            }
            const saveHandler = (req) => {
                if (req.editorID == props.editorID && props.editorID) {
                    console.log("saveEditor received!", 'comment', req)
                    saveRequest.value = req
                    saveChangesAndEmit()
                }
            }

            const parseEditorTags = ref(function () { })

            return {
                // tools
                emitter,
                // const
                commentTypeIDs,
                // models
                model, baseModel, oldModel, advancedMode, isEditing, errState, editor, userAgent,
                // data
                saveModalOpen, showSpinLoader, commentTypeModalOpen, dealNote, saveRequest, storeKey,
                // computed
                companyOwners, contacts, dirtyProps, isNew, userIsOwner,
                // methods 
                saveChanges, showCommentTypeModal, setCommentType, setCommentTypeAndSave, saveHandler, getModel, storeComment, parseEditorTags, deleteComment,
            }
        },
       
        data: function () {
            return {
                shareTarget: [],               
                selectedTag: _.cloneDeep(blankSelectedTag)
            }
        },
        computed:
        {
            ...mapGetters({
                currentUserID: 'userInfo/currentUserID',
                currentUser: 'userInfo/currentUser',
                flags: 'flags/flags',
                commentTypes: 'comments/commentTypes'
            }),

            
            isMiniView: function () {
                return true
            },
            availableFlags: function () {
                return _.filter(this.flags, function (f) { return f.key != 'bookmark' });
            },
            filteredCommentTypes: function () {
                return _.filter(this.commentTypes, function (commentType) { return [1, 2, 5].includes(commentType.id); });
                //return _.filter(this.commentTypes, function (commentType) { return [1, 2, 5].includes(commentType.id); }).concat(_.filter(this.commentTypes, function (commentType) { return [4].includes(commentType.id); }));
            },
            selectedContacts: function () {
                if (!this.model && !this.model.owners) return [];
                return this.model.owners.filter(contact => contact.ownerTypeID == 3).map(contact => contact.ownerID);
            },
            flagsByCategory: function () {
                var flags = {};
                _.each(this.flags, function (flag) {
                    flags[flag.biCategory + (flag.value ? flag.value : '')] = flag;
                });
                return flags;
            },
            commentFlags: function () {
                //active flags on this comment. returns dictionary (flagType, flag)
                var me = this;
                var commentFlags = {};
                if (this.model) {
                    _.each(this.flags, function (flag) {
                        commentFlags[flag.key] = _.filter(me.model.ownerUserFlags, function (commentFlag) {
                            return flag.biCategory == commentFlag.biCategory && (!flag.value || flag.value == commentFlag.value);
                        })[0];
                    });
                }

                return commentFlags;
            },
            ownerFlags: function () {
                //active flags on this comment's owner. returns dictionary (flagType, array of flags)
                var me = this;
                var ownerFlags = {};
                var companyOwnerUsers = this.obj && this.obj.users ? this.obj.users : [];

                _.each(this.flags, function (flag) {
                    ownerFlags[flag.key] = _.filter(companyOwnerUsers, function (ownerFlag) {
                        return flag.biCategory == ownerFlag.biCategory && (!flag.value || flag.value == ownerFlag.value);
                    });
                });

                //add the ones set in this comment that haven't been saved yet
                _.each(this.flags, function (flag) {
                    var flagName = flag.key;
                    var commentFlag = me.commentFlags[flagName];
                    if (commentFlag && _.filter(ownerFlags[flagName], function (flag) { return commentFlag.commentID == flag.commentID }).length == 0)
                        ownerFlags[flagName].push(commentFlag);
                });
                return ownerFlags;
            },
            userFlags: function () {
                //previous flags set by the user on this comment's owner. returns sorted array of flags
                var me = this;
                var companyOwnerUsers = this.obj && this.obj.users ? this.obj.users : [];
                var flags = _.filter(companyOwnerUsers, function (flag) {
                    if (flag.userID == me.model.entryUserID && flag.commentID != me.model.id && me.flagsByCategory[flag.biCategory + (flag.value ? flag.value : '')])
                        return flag;
                });

                return _.sortBy(flags, function (flag) {
                    return new Date(flag.date) * -1;
                });
            },
            hasCompany: function () {
                return _.find(this.model.owners, function (owner) { return owner.ownerTypeID == 1 && owner.ownerActive; });
            },
            hasContact: function () {
                return _.find(this.model.owners, function (owner) { return owner.ownerTypeID == 3 && owner.ownerActive; });
            },
            hasDeal: function () {
                return _.find(this.model.owners, function (owner) { return owner.ownerTypeID == 4 && owner.ownerActive; });
            },
            returnFactors: function () {
                //Check the type of note. Company note if any owner is a company, person note otherwise. Fill return factors
                var me = this;
                var ows;
                _.each([1, 3], function (type) {
                    if (!ows) ows = _.find(me.model.owners, function (owner) { return owner.ownerTypeID == type && owner.ownerActive; });
                });
                _.each([1, 3], function (type) {
                    if (!ows) ows = _.find(me.model.owners, function (owner) { return owner.ownerTypeID == type; });
                });

                if (ows) {
                    return {
                        ownerID: ows.ownerID,
                        ownerName: ows.ownerName,
                        ownerTypeID: ows.ownerTypeID,
                    }
                }
                else
                    return {
                        ownerID: 0,
                        ownerName: "",
                        ownerTypeID: 0
                    }
            }
        },
        methods: {
            selectedBuyersListChanged(ev) {
                console.log(ev)
            },
            isDirty() {
                if (this.model) {
                    return this.model.dirty;
                }
                return false
            },
            validateModel: function () {
                this.errState = null;
                var errorCount = 0;
                var allErrorMessages = [];

                if (!this.model.entryUserID)
                    allErrorMessages.push("The comment has no Entry User");

                if (this.model.description.trim().length == 0)
                    allErrorMessages.push("The comment can't be blank");

                if (this.model.owners.length == 0)
                    allErrorMessages.push("The comment needs at least one called Company or Contact");

                if (this.model.commentType && this.model.commentType != 5 && !this.hasContact)
                    allErrorMessages.push("The comment has no called Contact");


                if (allErrorMessages.length > 0) {
                    errorCount = allErrorMessages.length;

                    //if there's an error during the validation of a new comment let's reset the commentType so the commentType modal shows on the next save attempt
                    if (this.isNew && errorCount)
                        this.model.commentType = 0;
                }

                this.errState = errorCount ? { errorCount: errorCount, errorMessages: allErrorMessages } : null
                return this.errState
            },        
            saveTag: function (tagData) {
                var me = this;
                me.$refs.tagEditorModal.closeTheModal()

                if (tagData.html) {
                    if (me.selectedTag.node) {
                        me.selectedTag.node.replaceWith($(tagData.html));
                        me.parseAndMoveCaret(me.selectedTag.bm);
                        if (me.selectedTag.bm)
                            me.editor.insertContent(' ');
                    }
                    else {
                        me.editor.insertContent('' + tagData.html + ' ');
                        me.parseAndMoveCaret();
                    }
                }
            },
            togglePublic: function () {
                this.model.isPublic = !this.model.isPublic;
            },
            ownerChange: function (selected) {
                //handle the non-multiple selection case
                if (!Array.isArray(selected))
                    selected = selected ? [selected] : [];

                //make sure we always keep the company owners in the owners
                var newOwners = this.companyOwners.concat(selected);
                if (!_.isEqual(newOwners, this.model.owners))
                    this.model.owners = newOwners;
            },
            reminderSetFlag: function () {
                var target = this.$refs['reminderDate'].value;
                if (target) {
                    var date = DateTime.fromISO(target);
                    var value = date.toFormat('LL/dd/yyyy');

                    this.$refs['reminderDateModal'].closeTheModal();
                    this.editorSetFlag('flagAction', 'set', { reminderDate: value });
                }
            },
            shareSetFlag: function () {
                var target = this.shareTarget && this.shareTarget.length > 0 ? this.shareTarget[0] : null
                if (target && target.id && target.name) {
                    this.$refs['shareTargetModal'].closeTheModal();
                    this.editorSetFlag('shareAction', 'set', [{ id: target.id, name: target.name, objectTypeID: 8 }]);
                }
            },
            editorSetFlag: function (key, flagaction, value) {
                var me = this;
                if (me.userIsOwner) {

                    if (me.flags[key]) {
                        flagaction = flagaction ? flagaction : me.commentFlags[key] ? 'clear' : 'set';
                        me.editor.selection.dom.$('.cmdmHashTag[data-tagname="' + me.flags[key].hashtag + '"]').remove();

                        var res = me.setFlag(key, flagaction, value ? value : "");
                        if (res && res.newFlag) {
                            me.moveEditorCaret();

                            var tagHTML = me.editor.tagUtils.buildTagSpan({ name: me.flags[key].hashtag, ownerTypeID: 'hashtag', type: 'hashtag', value: res.hashTagValue });
                            me.editor.insertContent(' ' + tagHTML + ' ');
                        }
                        me.editor.execCommand('noflags-softparsetags');
                    }
                }
            },
            setFlag: function (key, flagaction, value) {
                var me = this;
                var flagdata = null;
                var hashValue = '';

                if (me.flags[key]) {
                    var ownerUserFlags = _.filter(me.model.ownerUserFlags, function (action) {
                        return (me.flags[key].biCategory != action.biCategory);
                    });

                    if (flagaction == 'set') {
                        flagdata = {};
                        flagdata.biCategory = me.flags[key].biCategory;
                        flagdata.commentID = me.model.id;
                        flagdata.ownerID = this.returnFactors.ownerID;
                        flagdata.ownerName = this.returnFactors.ownerName;
                        flagdata.ownerTypeID = this.returnFactors.ownerTypeID;
                        flagdata.description = me.model.description;
                        flagdata.date = new Date();
                        flagdata.userID = me.model.entryUserID;
                        flagdata.userName = me.model.entryUserName;

                        switch (key) {
                            case 'thumbsUp':
                                flagdata.value = 'ThumbUp';
                                hashValue = 'thumb_up';
                                break;
                            case 'thumbsDown':
                                flagdata.value = 'ThumbDown';
                                hashValue = 'thumb_down';
                                break;

                            case 'flagAction':
                                if (value && value.reminderDate) {
                                    hashValue = value.reminderDate;
                                    flagdata.reminderDate = new Date(value.reminderDate);
                                }
                                else {
                                    this.$refs.reminderDateModal.showTheModal();

                                    return { newFlag: null, hashTagValue: '' };
                                }
                                break;

                            case 'bellAction':
                                flagdata.targetID = 9;
                                flagdata.targetTypeID = 17;
                                flagdata.targetName = "Research";
                                hashValue = '';
                                break;

                            case 'shareAction':
                                if (value) {
                                    hashValue = _.map(value, function (target) {
                                        return {
                                            tagID: target.id, tagName: target.name, tagType: target.objectTypeID
                                        };
                                    });
                                    me.model.shareTargets = value;
                                    flagdata.shareTargets = value;

                                }
                                else {
                                    this.$refs.shareTargetModal.showTheModal();
                                    return { newFlag: null, hashTagValue: '' };
                                }
                                break;
                        }
                        ownerUserFlags.push(flagdata);
                    }
                    me.model.ownerUserFlags = ownerUserFlags;
                }

                return { newFlag: flagdata, hashTagValue: hashValue };
            },
            setFlagHandler(req) {
                console.log("setFlag received!", 'flagaction', req)
                if (this.editorID && req.editorID == this.editorID) {
                    var flagKey = req.action + 'Action'
                    if (req.action == 'bell')
                        this.editorSetFlag(flagKey, 'set', null)
                    else
                        this.setFlag(flagKey, 'set', null)
                }
            },
            dateChange: function (event) {
                var newValue = event.target.value;
                var parts;
                var newDate;

                if (newValue.indexOf('-') > -1) {
                    parts = newValue.split('-');
                    newDate = new Date(parts[0], parts[1] - 1, parts[2]);
                }
                else if (newValue.indexOf('/') > -1) {
                    parts = newValue.split('/');
                    newDate = new Date(parts[2], parts[0] - 1, parts[1]);
                }
                else {
                    newDate = newValue;
                }

                this.model.date = newDate;
            },

            //editor functions
            moveEditorCaret: function (unfocused) {
                var me = this;
                if (unfocused)
                    me.editor.focus();

                var root = me.editor.dom.getRoot();
                var lastnode = root.childNodes[root.childNodes.length - 1];
                lastnode = lastnode.childNodes[lastnode.childNodes.length - 1];
                me.editor.selection.select(lastnode);
                me.editor.selection.collapse(false);
            },
            syncActionFlags(updateFlags) {
                var me = this;

                //update the flag actions based on hashtags and keep the tags up to date
                if (me.editor && me.editor.selection) {
                    _.each(me.flags, function (flag) {
                        var cmdmTag = $(me.editor.selection.dom.$('.cmdmHashTag[data-tagname="' + flag.hashtag + '"]'));

                        //ignore the tags under the hidden container (happens after editing)
                        cmdmTag = _.filter(cmdmTag, function (tag) {
                            if ($(tag).parents('.mce-offscreen-selection').length == 0)
                                return true;
                        });

                        if (cmdmTag.length > 0) {
                            //delete dupes except the one further down in the editor
                            for (var i = 0; i < cmdmTag.length - 1; i++) {
                                if ($(cmdmTag[i]).parent('p').html() == $(cmdmTag[i])[0].outerHTML)
                                    $(cmdmTag[i]).parent('p').remove();
                                else
                                    $(cmdmTag[i]).remove();
                            }

                            //finally keep the tag
                            cmdmTag = $(cmdmTag[cmdmTag.length - 1]);

                            //iterate tags array and find the first matching tag
                            var matchingTag;
                            me.model.tags = _.filter(me.model.tags, function (tag) {
                                if (tag.name == cmdmTag.data('tagname')) {
                                    var hasValues = tag.values;
                                    var sameValues = tag.values[0].value == cmdmTag.data('tagvalue');
                                    var emptyValues = !tag.values[0].value && !cmdmTag.data('tagvalue');

                                    if (!matchingTag && hasValues && (sameValues || emptyValues)) {
                                        matchingTag = tag;
                                    }
                                    return false;
                                }
                                return true;
                            });

                            //make sure the tag is well formed 
                            var flagkey = '';
                            var initialValue = {};
                            switch (cmdmTag.data('tagname')) {
                                case 'rating':
                                    if (cmdmTag.data('tagvalue') == 'thumb_up' || cmdmTag.data('tagvalue') == 'thumb_down') {
                                        if (cmdmTag.data('tagvalue') == 'thumb_up' && flag.value == 'ThumbUp')
                                            flagkey = flag.key;
                                        else if (cmdmTag.data('tagvalue') == 'thumb_down' && flag.value == 'ThumbDown')
                                            flagkey = flag.key;
                                    }
                                    else
                                        cmdmTag.addClass('invalidTag');
                                    break;
                                case 'flag':
                                    //regex for "MM/DD/YYYY"
                                    var date_regex = /^(0[1-9]|1[0-2])\/(0[1-9]|1\d|2\d|3[01])\/(19|20)\d{2}$/;
                                    if ((date_regex.test((cmdmTag.data('tagvalue'))))) {
                                        flagkey = flag.key;
                                        initialValue.reminderDate = "" + cmdmTag.data('tagvalue') + "";
                                    }
                                    else
                                        cmdmTag.addClass('invalidTag');
                                    break;
                                case 'share':
                                    if (cmdmTag.data('tagvalue') && cmdmTag.data('tagid') && (cmdmTag.data('tagtype') == 3 || cmdmTag.data('tagtype') == 8)) {

                                        flagkey = flag.key;
                                        initialValue = [{ id: cmdmTag.data('tagid'), name: "" + cmdmTag.data('tagvalue'), objectTypeID: cmdmTag.data('tagtype') }];

                                        var tagindex = 1;
                                        while (cmdmTag.data('tagvalue' + tagindex) && cmdmTag.data('tagid' + tagindex) && (cmdmTag.data('tagtype' + tagindex) == 3 || cmdmTag.data('tagtype' + tagindex) == 8)) {
                                            initialValue.push({ id: cmdmTag.data('tagid' + tagindex), name: "" + cmdmTag.data('tagvalue' + tagindex), objectTypeID: cmdmTag.data('tagtype' + tagindex) });
                                            tagindex++;
                                        }
                                    }
                                    else
                                        cmdmTag.addClass('invalidTag');
                                    break;
                                case 'research':
                                    flagkey = flag.key;
                                    break;
                            }

                            //if the tag is well formed, we need to make sure it's set in the ownersArray
                            if (!cmdmTag.hasClass('invalidTag')) {
                                //we also need to make sure it's put back in the tags array
                                if (matchingTag)
                                    me.model.tags.push(matchingTag);

                                //put in the ownerUser array - unless we got here via setFlag to begin with
                                if (updateFlags && flagkey)
                                    me.setFlag(flagkey, 'set', initialValue);
                            }
                        }

                        //if we couldn't find a tag type in the editor, or if the tag is invalid
                        //then let's make sure it gets removed from the ownerUser array
                        if (cmdmTag.length == 0 || cmdmTag.hasClass('invalidTag')) {
                            if (updateFlags)
                                me.setFlag(flag.key, 'clear', {});
                        }
                    });
                }
            },
            loadComment: function (comment) {
                var me = this;
                if (comment.values) {
                    var keys = Object.keys(comment.values);
                    for (var i = 0; i < keys.length; i++) {
                        me.model[keys[i]] = comment.values[keys[i]];
                    }
                }

                //put it in the editor
                if (me.model.html) {
                    me.editor.setContent(me.model.html);
                    me.editor.execCommand('softparsetags');
                }
                me.moveEditorCaret(true);
            },
            hasOpenModals: function () {
                var me = this;
                if (me.$refs.tagEditorModal && me.$refs.tagEditorModal.isOpen)
                    return true;
                else
                    return false;
            },
            parseAndMoveCaret: function (bm) {
                var me = this;
                if (!bm) bm = me.editor.selection.getBookmark();
                me.editor.execCommand('softparsetags');
                me.editor.selection.moveToBookmark(bm);
            },
            //needs commentID and objectKey
            fetch(opt) {
                console.log('fetchComment', opt)
                this.showSpinLoader = true
                return new Promise((resolve, reject) => {
                    this.$store.dispatch('comments/fetchComment', opt)
                        .then((results) => {
                            this.showSpinLoader = false
                            if (results && results.comment) {
                                this.model = _.cloneDeep(results.comment)
                            }
                            console.log('loaded Comment', results.comment)
                            resolve()
                        }).catch(error => {
                            this.showSpinLoader = false
                            reject(error)
                        })
                })
            }
        },
        created() {
            var me = this;
            me.showSpinLoader = true;
            me.useFullTagEditor = true;
            me.openNewTagInEditor = true;

            me.tinyConfig = {
                toolbar: 'addhashtag addattag | ' + me.commentTypeIDs + ' dealref | ndasigned | advmode',
                toolbar_mode: 'wrap',
                mobile: { toolbar_mode: 'wrap' },
                plugins: "code importcss noneditable autoresize tinymcespellchecker powerpaste linkchecker media mediaembed",
                content_style:
                    " .cmdmHashTag { color: #7F76EF !important; font-weight: 500 !important; margin-right: 3px !important; }" +
                    " .cmdmHashTag.invalidTag { color: #DE3232 !important; font-weight: 500 !important; margin-right: 3px !important; }" +
                    " .cmdmAtTag { color:#00A8E6 !important; font-weight: 500 !important; margin-right: 3px !important; }" +
                    " .cmdmAtTag.invalidTag { color: #DE3232 !important; font-weight: 500 !important; margin-right: 3px !important; }",

                contextmenu: "spellchecker edittag undo redo paste",
                noneditable_noneditable_class: "cmdmTag",
                powerpaste_allow_local_images: false,
                powerpaste_word_import: 'prompt',
                powerpaste_html_import: 'prompt',
                paste_as_text: true,
                menubar: false,
                statusbar: false,
                width: "100%",
                autoresize_bottom_margin: '30',
                autoresize_on_init: true,
                draggable_modal: true,
                setup: function (editor) {
                    me.editor = editor;
                    me.editor.tagUtils = tagHelper.tagUtils;
                    if (!me.userIsOwner) me.editor.mode.set("readonly");

                    //these functions have to be here for the tinymce autocompleter to see them
                    var tagsTimestamp = new Date();
                    var searchTags = function (text, tagSymbol, urlSearch) {
                        clearTimeout(me.editor.idleTimer);
                        return new Promise(function (resolve) {
                            var requestTimestamp = new Date();
                            axios.get("/Search/" + urlSearch + "TagSearch?term=" + text + "&Limit=5&Expect" + urlSearch + "Sign=false")
                                .then(r => r.data)
                                .then(results => {
                                    //the real problem is the data level is processing these in paralell. we have to fix the search views
                                    if (requestTimestamp >= tagsTimestamp) {
                                        tagsTimestamp = requestTimestamp;

                                        var tags = [];
                                        //massage the tag data so it shows correctly on the autocompleter
                                        for (var i = 0; i < results.length; i++) {
                                            var tag = results[i];

                                            tags.push({
                                                text: tag.tag + " (" + tag.value + ")",
                                                tagname: tag.tag,
                                                value: JSON.stringify({
                                                    name: tag.type == 'hashtag' && tag.tag ? tag.tag : '',
                                                    value: tag.type == 'hashtag' && tag.tag ? '' : tag.tag,
                                                    values: [],
                                                    type: tag.type,
                                                    ownerID: tag.id,
                                                    ownerTypeID: tag.type
                                                })
                                            });
                                        }

                                        //allow the value we searched for if it doesn't exist, as long as its a hashtag
                                        if (tagSymbol == '#' && (tags.length == 0 || tags[0].tagname != text)) {

                                            //see if the reason the tag doesn't exist because we're trying to add an initial value
                                            var iColon = text.indexOf(':');
                                            var tagname = text;
                                            var tagvalue = "";
                                            if (iColon != -1) {
                                                tagname = text.substr(0, iColon);
                                                tagvalue = text.substr(iColon + 1, text.length - 1);
                                            }

                                            tags.unshift({
                                                text: tagname + (tagname == text ? " (0)" : " (...)"),
                                                value: JSON.stringify({
                                                    name: tagname,
                                                    value: tagvalue,
                                                    values: [],
                                                    type: 'hashtag',
                                                    ownerTypeID: 'hashtag'
                                                })
                                            });
                                            if (tags.length > 5)
                                                tags.pop();
                                        }

                                        resolve(tags);
                                    }
                                });
                        })
                        
                    };

                    var insertTag = function (autocompleteApi, rng, value) {
                        me.selectedTag = _.cloneDeep(blankSelectedTag);

                        clearTimeout(me.editor.idleTimer);
                        me.editor.selection.setRng(rng);
                        autocompleteApi.hide();

                        var tag = JSON.parse(value);
                        tag.type = tag.ownerID && tag.ownerTypeID && tag.type != 'hashtag' ? 'attag' : 'hashtag';

                        //choose editor type
                        var id = 'cmdm' + tag.type + '-' + DateTime.now().toString().replace(/\./g, '').replace(/:/g, '');
                        me.editor.insertContent('' + me.editor.tagUtils.buildTagSpan(tag, id) + ' ');

                        //chose whether to open editor mode when adding
                        if (me.openNewTagInEditor && tag.type != 'attag') {
                            var bm = me.editor.selection.getBookmark();
                            me.editor.selection.select(me.editor.dom.select('#'+id)[0]);

                            var $node = $(me.editor.selection.getNode());
                            var tagNode = me.editor.tagUtils.getTagDataFromSpan($node);
                            if (tagNode) {
                                me.selectedTag = { tag: tagNode, node: $node, tagType: (tag.type == 'attag' ? 'AtTag' : 'HashTag') };
                                me.$refs.tagEditorModal.showTheModal();
                            }
                        }
                        me.parseAndMoveCaret(bm);
                    };


                    //autocompleters
                    me.editor.ui.registry.addAutocompleter('hashtag', {
                        ch: '#',
                        minChars: 1,
                        columns: 1,
                        fetch: function (text) {
                            return searchTags(text, '#', 'Hash');
                        },
                        onAction: insertTag
                    });
                    me.editor.ui.registry.addAutocompleter('attag', {
                        ch: '@',
                        minChars: 1,
                        columns: 1,
                        fetch: function (text) {
                            return searchTags(text, '@', 'At');
                        },
                        onAction: insertTag
                    });

                    //hashtag button
                    me.editor.ui.registry.addIcon('hashtag', '<svg height="17" width="17" viewBox="0 0 448 512" class="svg-inline--fa fa-hashtag fa-w-14 fa-7x"><path fill="#A5AED1" d="M440.667 182.109l7.143-40c1.313-7.355-4.342-14.109-11.813-14.109h-74.81l14.623-81.891C377.123 38.754 371.468 32 363.997 32h-40.632a12 12 0 0 0-11.813 9.891L296.175 128H197.54l14.623-81.891C213.477 38.754 207.822 32 200.35 32h-40.632a12 12 0 0 0-11.813 9.891L132.528 128H53.432a12 12 0 0 0-11.813 9.891l-7.143 40C33.163 185.246 38.818 192 46.289 192h74.81L98.242 320H19.146a12 12 0 0 0-11.813 9.891l-7.143 40C-1.123 377.246 4.532 384 12.003 384h74.81L72.19 465.891C70.877 473.246 76.532 480 84.003 480h40.632a12 12 0 0 0 11.813-9.891L151.826 384h98.634l-14.623 81.891C234.523 473.246 240.178 480 247.65 480h40.632a12 12 0 0 0 11.813-9.891L315.472 384h79.096a12 12 0 0 0 11.813-9.891l7.143-40c1.313-7.355-4.342-14.109-11.813-14.109h-74.81l22.857-128h79.096a12 12 0 0 0 11.813-9.891zM261.889 320h-98.634l22.857-128h98.634l-22.857 128z" class=""></path></svg>');
                    me.editor.ui.registry.addButton('addhashtag', {
                        icon: 'hashtag',
                        onAction: function () {
                            me.selectedTag = _.cloneDeep(blankSelectedTag);
                            me.selectedTag.tagType = 'HashTag'
                            me.$refs.tagEditorModal.showTheModal()

                        }
                    });

                    //attag button
                    me.editor.ui.registry.addIcon('attag', '<svg height="17" width="17" viewBox="0 0 448 512" class="svg-inline--fa fa-at fa-w-16 fa-7x"><path fill="#A5AED1" d="M256 8C118.941 8 8 118.919 8 256c0 137.059 110.919 248 248 248 48.154 0 95.342-14.14 135.408-40.223 12.005-7.815 14.625-24.288 5.552-35.372l-10.177-12.433c-7.671-9.371-21.179-11.667-31.373-5.129C325.92 429.757 291.314 440 256 440c-101.458 0-184-82.542-184-184S154.542 72 256 72c100.139 0 184 57.619 184 160 0 38.786-21.093 79.742-58.17 83.693-17.349-.454-16.91-12.857-13.476-30.024l23.433-121.11C394.653 149.75 383.308 136 368.225 136h-44.981a13.518 13.518 0 0 0-13.432 11.993l-.01.092c-14.697-17.901-40.448-21.775-59.971-21.775-74.58 0-137.831 62.234-137.831 151.46 0 65.303 36.785 105.87 96 105.87 26.984 0 57.369-15.637 74.991-38.333 9.522 34.104 40.613 34.103 70.71 34.103C462.609 379.41 504 307.798 504 232 504 95.653 394.023 8 256 8zm-21.68 304.43c-22.249 0-36.07-15.623-36.07-40.771 0-44.993 30.779-72.729 58.63-72.729 22.292 0 35.601 15.241 35.601 40.77 0 45.061-33.875 72.73-58.161 72.73z" class=""></path></svg>');
                    me.editor.ui.registry.addButton('addattag', {
                        icon: 'attag',
                        onAction: function () {
                            me.selectedTag = _.cloneDeep(blankSelectedTag);
                            me.selectedTag.tagType = 'AtTag'
                            me.$refs.tagEditorModal.showTheModal()
                        }
                    });

                    //comment types buttons
                    if (!me.isNew) {
                        if ([1, 2, 5].includes(me.model.commentType)) {
                            me.editor.ui.registry.addIcon('comment1', '<svg height="17" width="22" viewBox="0 0 448 512" class="svg-inline--fa fa-comments fa-w-18 fa-7x"><path fill="#A5AED1" d="M532 386.2c27.5-27.1 44-61.1 44-98.2 0-80-76.5-146.1-176.2-157.9C368.3 72.5 294.3 32 208 32 93.1 32 0 103.6 0 192c0 37 16.5 71 44 98.2-15.3 30.7-37.3 54.5-37.7 54.9-6.3 6.7-8.1 16.5-4.4 25 3.6 8.5 12 14 21.2 14 53.5 0 96.7-20.2 125.2-38.8 9.2 2.1 18.7 3.7 28.4 4.9C208.1 407.6 281.8 448 368 448c20.8 0 40.8-2.4 59.8-6.8C456.3 459.7 499.4 480 553 480c9.2 0 17.5-5.5 21.2-14 3.6-8.5 1.9-18.3-4.4-25-.4-.3-22.5-24.1-37.8-54.8zm-392.8-92.3L122.1 305c-14.1 9.1-28.5 16.3-43.1 21.4 2.7-4.7 5.4-9.7 8-14.8l15.5-31.1L77.7 256C64.2 242.6 48 220.7 48 192c0-60.7 73.3-112 160-112s160 51.3 160 112-73.3 112-160 112c-16.5 0-33-1.9-49-5.6l-19.8-4.5zM498.3 352l-24.7 24.4 15.5 31.1c2.6 5.1 5.3 10.1 8 14.8-14.6-5.1-29-12.3-43.1-21.4l-17.1-11.1-19.9 4.6c-16 3.7-32.5 5.6-49 5.6-54 0-102.2-20.1-131.3-49.7C338 339.5 416 272.9 416 192c0-3.4-.4-6.7-.7-10C479.7 196.5 528 238.8 528 288c0 28.7-16.2 50.6-29.7 64z" class=""></path></svg>');
                            me.editor.ui.registry.addIcon('comment2', '<svg height="17" width="22" viewBox="0 0 448 512" class="svg-inline--fa fa-comment-alt-lines fa-w-16 fa-5x"><path fill="#A5AED1" d="M448 0H64C28.7 0 0 28.7 0 64v288c0 35.3 28.7 64 64 64h96v84c0 7.1 5.8 12 12 12 2.4 0 4.9-.7 7.1-2.4L304 416h144c35.3 0 64-28.7 64-64V64c0-35.3-28.7-64-64-64zm16 352c0 8.8-7.2 16-16 16H288l-12.8 9.6L208 428v-60H64c-8.8 0-16-7.2-16-16V64c0-8.8 7.2-16 16-16h384c8.8 0 16 7.2 16 16v288zm-96-216H144c-8.8 0-16 7.2-16 16v16c0 8.8 7.2 16 16 16h224c8.8 0 16-7.2 16-16v-16c0-8.8-7.2-16-16-16zm-96 96H144c-8.8 0-16 7.2-16 16v16c0 8.8 7.2 16 16 16h128c8.8 0 16-7.2 16-16v-16c0-8.8-7.2-16-16-16z" class=""></path></svg>');
                            me.editor.ui.registry.addIcon('comment5', '<svg height="17" width="22" viewBox="0 0 448 512" class="svg-inline--fa fa-info-circle fa-w-16 fa-5x"><path fill="#A5AED1" d="M256 8C119.043 8 8 119.083 8 256c0 136.997 111.043 248 248 248s248-111.003 248-248C504 119.083 392.957 8 256 8zm0 448c-110.532 0-200-89.431-200-200 0-110.495 89.472-200 200-200 110.491 0 200 89.471 200 200 0 110.53-89.431 200-200 200zm0-338c23.196 0 42 18.804 42 42s-18.804 42-42 42-42-18.804-42-42 18.804-42 42-42zm56 254c0 6.627-5.373 12-12 12h-88c-6.627 0-12-5.373-12-12v-24c0-6.627 5.373-12 12-12h12v-64h-12c-6.627 0-12-5.373-12-12v-24c0-6.627 5.373-12 12-12h64c6.627 0 12 5.373 12 12v100h12c6.627 0 12 5.373 12 12v24z" class=""></path></svg>');

                            var buttonAPI = [];

                            _.each(me.filteredCommentTypes, function (commentType) {
                                me.editor.ui.registry.addToggleButton("commentType" + commentType.id,
                                    {
                                        icon: 'comment' + commentType.id,
                                        text: commentType.entry,
                                        tooltip: commentType.entry,
                                        onAction: function (button) {
                                            //turn buttons off
                                            _.each(buttonAPI, function (b) {
                                                b.setActive(false);
                                            });

                                            me.model.commentType = commentType.id;
                                            button.setActive(true);
                                        },
                                        onSetup: function (button) {
                                            buttonAPI.push(button);
                                            if (me.model.commentType == commentType.id)
                                                button.setActive(true);
                                        }
                                    });
                            });
                        }
                        else if (me.model.commentType == 4) {
                            me.editor.ui.registry.addIcon('comment4', '<svg height="17" width="22" viewBox="0 0 448 512" class="svg-inline--fa fa-info-circle fa-w-16 fa-5x"><path fill="#A5AED1" d="M256 8C119.043 8 8 119.083 8 256c0 136.997 111.043 248 248 248s248-111.003 248-248C504 119.083 392.957 8 256 8zm0 448c-110.532 0-200-89.431-200-200 0-110.495 89.472-200 200-200 110.491 0 200 89.471 200 200 0 110.53-89.431 200-200 200zm0-338c23.196 0 42 18.804 42 42s-18.804 42-42 42-42-18.804-42-42 18.804-42 42-42zm56 254c0 6.627-5.373 12-12 12h-88c-6.627 0-12-5.373-12-12v-24c0-6.627 5.373-12 12-12h12v-64h-12c-6.627 0-12-5.373-12-12v-24c0-6.627 5.373-12 12-12h64c6.627 0 12 5.373 12 12v100h12c6.627 0 12 5.373 12 12v24z" class=""></path></svg>');
                            me.editor.ui.registry.addToggleButton("dealref",
                                {
                                    icon: 'comment4',
                                    text: "Deal Reference",
                                    tooltip: 'Deal Reference',
                                    onAction: function () {
                                        window.open('/BuyersList/Details/' + me.model.auxiliaryID, '_blank');
                                    },
                                    onSetup: function (button) {
                                        button.setActive(true);
                                    }
                                });
                        }
                    }

                    //nda signed button
                    me.editor.ui.registry.addIcon('file-signature', '<svg height="17" width="22" viewBox="0 0 576 512" class="svg-inline--fa fa-file-signature fa-w-18 fa-5x"><path fill="#A5AED1" d="M568.54 167.33l-31.87-31.87c-9.94-9.94-26.07-9.94-36.01 0l-27.25 27.25 67.88 67.88 27.25-27.25c9.95-9.94 9.95-26.07 0-36.01zM329.06 306a63.974 63.974 0 0 0-16.26 27.11L297.57 384h-24.76c-4.28 0-8.12-2.38-10.16-6.5-11.97-23.86-46.28-30.34-66-14.17l-13.88-41.62c-3.28-9.81-12.44-16.41-22.78-16.41s-19.5 6.59-22.78 16.41L119 376.36c-1.5 4.58-5.78 7.64-10.59 7.64H96c-8.84 0-16 7.16-16 16s7.16 16 16 16h12.41c18.62 0 35.09-11.88 40.97-29.53L160 354.58l16.81 50.48a15.994 15.994 0 0 0 14.06 10.89c.38.03.75.05 1.12.05 6.03 0 11.59-3.41 14.31-8.86l7.66-15.33c2.78-5.59 7.94-6.19 10.03-6.19s7.25.59 10.19 6.53c7.38 14.7 22.19 23.84 38.62 23.84H336V464H47.99V48.09h159.97v103.98c0 13.3 10.7 23.99 24 23.99H336v78l48-47.58v-74.5c0-12.7-5.17-25-14.17-33.99L285.94 14.1c-9-9-21.2-14.1-33.89-14.1H47.99C21.5.1 0 21.6 0 48.09v415.92C0 490.5 21.5 512 47.99 512h287.94c26.5 0 48.07-21.5 48.07-47.99V388.8l134.66-135.58-67.88-67.88L329.06 306zM255.95 51.99l76.09 76.08h-76.09V51.99z"></path></svg >');
                    me.editor.ui.registry.addButton("ndasigned",
                        {
                            icon: 'file-signature',
                            text: "NDA Signed",
                            tooltip: 'NDA Signed',
                            onAction: function () {
                                me.editor.selection.dom.$('.cmdmHashTag[data-tagname="NDA_Signed"]').remove();

                                me.moveEditorCaret();
                                var tagHTML = me.editor.tagUtils.buildTagSpan({ name: 'NDA_Signed', ownerTypeID: 'hashtag', type: 'hashtag', value: '' });
                                me.editor.insertContent(' ' + tagHTML + ' ');
                                me.editor.execCommand('softparsetags');
                            },
                            onSetup: function () {

                            }
                        });

                    //advanced button options
                    me.editor.ui.registry.addIcon('advanced', '<svg height="17" width="22" viewBox="0 0 576 512" class="svg-inline--fa fa-sliders-h-square fa-w-14 fa-7x"><path fill="#A5AED1" d="M400 32H48C21.5 32 0 53.5 0 80v352c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48V80c0-26.5-21.5-48-48-48zm-6 400H54c-3.3 0-6-2.7-6-6V86c0-3.3 2.7-6 6-6h340c3.3 0 6 2.7 6 6v340c0 3.3-2.7 6-6 6zm-42-244v8c0 6.6-5.4 12-12 12H192v24c0 13.3-10.7 24-24 24h-16c-13.3 0-24-10.7-24-24v-24h-20c-6.6 0-12-5.4-12-12v-8c0-6.6 5.4-12 12-12h20v-24c0-13.3 10.7-24 24-24h16c13.3 0 24 10.7 24 24v24h148c6.6 0 12 5.4 12 12zm0 128v8c0 6.6-5.4 12-12 12h-20v24c0 13.3-10.7 24-24 24h-16c-13.3 0-24-10.7-24-24v-24H108c-6.6 0-12-5.4-12-12v-8c0-6.6 5.4-12 12-12h148v-24c0-13.3 10.7-24 24-24h16c13.3 0 24 10.7 24 24v24h20c6.6 0 12 5.4 12 12z"></path></svg >');
                    me.editor.ui.registry.addMenuButton("advmode",
                        {
                            icon: 'advanced',
                            text: "Tools",
                            tooltip: 'Advanced Options',
                            fetch: function (callback) {
                                var items = [
                                    {
                                        type: 'menuitem',
                                        text: 'Undo',
                                        icon: 'undo',
                                        onAction: function () { me.editor.execCommand('Undo'); }
                                    },
                                    {
                                        type: 'menuitem',
                                        text: 'Redo',
                                        icon: 'redo',
                                        onAction: function () { me.editor.execCommand('Redo'); }
                                    },
                                    {
                                        type: 'menuitem',
                                        text: 'Paste',
                                        icon: 'paste',
                                        onAction: function () { me.editor.execCommand('Paste'); }
                                    },
                                    {
                                        type: 'separator'
                                    },
                                    {
                                        type: 'menuitem',
                                        text: 'Parse Tags',
                                        icon: 'search',
                                        onAction: function () {
                                            me.editor.execCommand('parsetags');
                                        }
                                    },
                                    {
                                        type: 'menuitem',
                                        text: 'Code',
                                        icon: 'sourcecode',
                                        onAction: function () {
                                            me.editor.execCommand('mceCodeEditor');
                                        }
                                    },

                                ];
                                items.push(
                                    {
                                        type: 'separator'
                                    },
                                    {
                                        type: 'menuitem',
                                        text: 'Stored Comments',
                                        icon: 'comment-add',
                                        onAction: function () {
                                            me.$refs.storeModal.showTheModal();
                                        }
                                    }
                                );

                                callback(items);
                            }
                        });

                    //edit tag contextual button
                    me.editor.ui.registry.addMenuItem('edittag', {
                        text: 'Edit Tag',
                        context: 'cmdmtags',
                        onAction: function () {
                            me.selectedTag = _.cloneDeep(blankSelectedTag);

                            var node = me.editor.selection.getNode();
                            if ($(node.parentNode).hasClass('cmdmTag')) {
                                node = node.parentNode;
                                me.editor.selection.select(node)
                            }

                            var $node = $(node);
                            var tag = me.editor.tagUtils.getTagDataFromSpan($node);
                            if (tag) {
                                me.editor.selection.collapse();
                                var bm = me.editor.selection.getBookmark();

                                me.selectedTag = { tag: tag, node: $node, bm: bm, tagType: (tag.name == 'atTag' ? 'AtTag' : 'HashTag') }
                                me.$refs.tagEditorModal.showTheModal()

                                // me.parseAndMoveCaret(bm);
                            }
                        }
                    });

                    //parse function
                    me.parseEditorTags = function (updateEditor, updateFlags, forceUpdate) {
                        me.editor.tagUtils.parseContent(me.editor.getContent(), function (tagResponse) {
                            clearTimeout(me.editor.idleTimer);

                            var html = me.editor.tagUtils.cleanUpWhiteSpace("<p>&nbsp;</p>", tagResponse.html);
                            var tags = tagResponse.tags;

                            if (forceUpdate || me.model.html != html) {
                                //reset the tags
                                me.model.tags = [];

                                //validate, dedupe and add the new tags
                                if (tags) {
                                    for (var i = 0; i < tags.length; i++) {
                                        var tagToAdd = tags[i];
                                        if (tagToAdd.values[0] && tagToAdd.values[0].ownerID != -1) {
                                            var tagAdded = _.filter(me.model.tags, function (tag) { return me.editor.tagUtils.deepCompareTags(tagToAdd, tag); });
                                            if (tagAdded.length == 0) {
                                                tagToAdd.addToCompany = true;
                                                me.model.tags.push(tagToAdd);
                                            }
                                        }
                                    }
                                }

                                //update the comment desc/html and set it to the editor if needed
                                var commentText = new DOMParser().parseFromString(html, "text/html").documentElement.textContent;
                                me.model.description = commentText;
                                me.model.html = html;

                                //sync the action hashtags
                                me.syncActionFlags(updateFlags);
                                console.log('tags parsed and updated', me.model.tags)

                                //save to local cache
                                me.storeComment();

                            }

                            if (updateEditor) {
                                me.editor.setContent(html);
                                me.moveEditorCaret(true);
                            }
                        });
                    }

                    // command to parse complete DOM for tags without updating the editor or updating userflags
                    me.editor.addCommand('noflags-softparsetags', function () {
                        me.parseEditorTags(false, false);
                    });

                    // command to parse complete DOM for tags without updating the editor
                    me.editor.addCommand('softparsetags', function () {
                        me.parseEditorTags(false, true);
                    });

                    // command to parse complete DOM for tags
                    me.editor.addCommand('parsetags', function () {
                        me.parseEditorTags(true, true);
                    });


                    //init event
                    me.editor.on('init', function () {
                        if (me.model.html) {
                            me.editor.setContent(me.model.html);
                            me.editor.execCommand('softparsetags');
                        }
                        me.showSpinLoader = false;
                        me.model.dirty = false;
                        me.$emit('editorloaded');

                        me.moveEditorCaret(true);

                    });

                    //softparse on any tinymce change after 10s of inactivity
                    me.editor.on('paste undo redo keyup', function () {
                        //mark dirty
                        if (!me.model.dirty)
                            me.model.dirty = true

                        clearTimeout(me.editor.idleTimer);
                        me.editor.idleTimer = setTimeout(function () {
                            //softparse
                            if ($('.tox-autocompleter').length == 0 && $('.tox-dialog').length == 0 && !me.hasOpenModals())
                                me.editor.execCommand('softparsetags');
                        }, 10000);
                    });

                    // full parse when lose focus
                    me.editor.on('blur', function () {
                        if ($('.tox-dialog').length == 0 && !me.hasOpenModals())
                            me.editor.execCommand('parsetags');
                    });
                }
            }

            //init storage for comments
            storeHelper.storeUtils.initStorage('comment');
        },
        mounted() {
            this.emitter.on("saveEditor", this.saveHandler)
            this.emitter.on("setFlag", this.setFlagHandler)
            this.userAgent = this.model && this.model.entryUserID ? [{ id: this.model.entryUserID, name: this.model.entryUserName }] : [{ id: this.currentUser.id, name: this.currentUser.Name }]
        },
        beforeUnmount() {
            this.emitter.off("saveEditor", this.saveHandler)
            this.emitter.off("setFlag", this.setFlagHandler)
        },
        watch: {
            "modelValue": {
                handler() {
                    this.model = this.getModel()
                }
            },

            "model": {
                handler() {
                    this.isEditing = false
                    nextTick(() => {
                        this.model.dirty = false
                        this.baseModel = _.cloneDeep(this.model)
                    })
                }
            },
            "userAgent": function (newVal) {
                if (newVal && newVal.length > 0 && newVal[0].id) {
                    this.model.entryUserID = newVal[0].id
                    this.model.entryUserName = newVal[0].name
                }
                else {
                    this.model.entryUserID = 0
                    this.model.entryUserName = "No user"
                }

                //fix userFlags array
                if (this.model.ownerUserFlags) {
                    var me = this;
                    _.each(me.model.ownerUserFlags, function (userFlag) {
                        userFlag.userID = me.model.entryUserID;
                        userFlag.userName = me.model.entryUserName;
                    });
                }
            },
            'dealNote.selectedBuyersList': function () {
                if (this.isEditing) {
                    if (this.model.commentType == 4 && this.dealNote.selectedBuyersList && this.dealNote.selectedBuyersList.id) {
                        console.log(this.dealNote.selectedBuyersList)
                    }
                }
            },
            "dirtyProps": {
                handler(val, oldValue) {
                    var changed = []
                    Object.keys(val).forEach((key) => {
                        if (val[key] != oldValue[key])
                            changed.push(key)
                    })

                    if (changed.length) {
                        console.log('model dirtyProps deep watch', this.model.id, changed)
                        this.model.dirty = true
                        if (!this.showSpinLoader) {
                            this.validateModel()
                            this.$emit('dirtied', val)
                        }
                    }
                },
                deep: true
            }
        }
    }
 
</script>

<style scoped lang="scss">

</style>

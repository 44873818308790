<template>
    <record-owners v-if="objectType != 'emaillog'" :obj="obj" :ignoredTypes="['webuser']" />
    <template v-if="objectType == 'company'">
        <p v-if="obj.ownershipTypeID >= 3" class="record-ownership">
            {{ (obj.ownershipTypeID == 4 ? "portfolio of " : "Sub. of ") }}
            <a v-if="ownerIsLink" :href="'/Company/detailspage/' + obj.ownerID" target="_blank">
                <span v-html="highlightedParent" />
            </a>
            <template v-else>
                <span v-html="highlightedParent" />
            </template>
            <template v-if="obj.ownerID && obj.ultimateParentID && obj.ownerID != obj.ultimateParentID">
                and
                <a v-if="ownerIsLink" :href="'/Company/detailspage/' + obj.ultimateParentID" target="_blank">
                    <span v-html="highlightedUltimateParent" />
                </a>
                <template v-else>
                    <span v-html="highlightedUltimateParent" />
                </template>
            </template>

            <template v-if="acquiredEvent">
                since
                <a v-if="ownerIsLink" :href="'/Acquisition/detailspage/' + acquiredEvent.ownerID" target="_blank">
                    {{ acquiredEventDate }}
                </a>
                <span v-else>{{ acquiredEventDate }}</span>
            </template>
        </p>
    </template>
    <template v-if="objectType=='person'">

    </template>
    <template v-if="objectType=='query'">
    </template>
    <template v-if="objectType=='event'">
        <div v-for="rel in obj.eventRelationships" :key="rel.key">
            <a :href="getTitleURL(rel)" target="_blank" class="record-eventurl">
                {{ rel.name }}
            </a>
        </div>

    </template>
</template>
<script>
    import RecordOwners from './RecordOwners.vue'
    import { computed } from 'vue'
    import { getRecordTools } from '../../composables/RecordTools.js'
    import { getTextTools } from '../../composables/TextTools.js'
    import _ from 'lodash'

    export default {
        name: 'RecordSubtitle',
        components: {
            RecordOwners
        },
        props: {
            'obj': { type: Object },
            'recordContext': { type: String, default: 'details' }
        },
        setup(props) {
            // tools
            const linkContexts = ['details', 'table', 'pin-card', 'sidebar', 'detailspage']
            // computed
            const { objectType, highlightText, getTitleURL } = getRecordTools(props)
            const acquiredEvent = computed(() => {
                if (props.obj.events) {
                    var acqs = _.filter(props.obj.events, function (ev) { return ev.eventType == 7 && ev.ownerTypeID == 2 })
                    if (acqs && acqs.length > 0) {
                        return acqs[0]
                    }
                }
                return null
            })
            const acquiredEventDate = computed(() => acquiredEvent.value ? toShortDateString(acquiredEvent.value.date): null)
            const ownerIsLink = computed(() => linkContexts.includes(props.recordContext))
            const highlightedParent = computed(() => decorateHighlights({ body: props.obj.parentName, tokens: highlightText ? highlightText.value : [] }))
            const highlightedUltimateParent = computed(() => decorateHighlights({ body: props.obj.ultimateParentName, tokens: highlightText ? highlightText.value : [] }))
           
            // methods
            const { toShortDateString, decorateHighlights } = getTextTools()
            return {
                // tools
                highlightText, linkContexts,
                // computed
                objectType, acquiredEvent, acquiredEventDate, ownerIsLink, highlightedParent, highlightedUltimateParent,
                // methods
                toShortDateString, decorateHighlights, getTitleURL
            }
        }
    }
</script>
<style scoped lang="scss">
</style>

<template>
    <div v-if="!isFullInfoContext" class="event-item event" :class="email.outbound ? 'outbound-email' : 'inbound-email'">
        <i class="event-icon fa-comments"></i>
        <span class="event-label">{{email.outbound ? 'Outbound' : 'Inbound'}} Email</span>&nbsp;@&nbsp;
        <span v-if="email.toUrl" class="event-label"><a :href="email.toUrl">{{ email.to }}</a><span v-if="email.toLength>1"> and {{email.toLength}} more</span></span>
        <span v-else class="event-label">Unknown ({{ email.to }})</span>
        by&nbsp;
        <span v-if="email.fromUrl"><a :href="email.fromUrl" style="color:#464163 !important">{{ email.from }}</a><span v-if="email.fromLength>1"> and {{email.fromLength}} more</span></span>
        <span v-else style="color:#464163 !important">Unknown ({{ email.from }})</span>
    </div>
</template>

<script>
    import { computed } from 'vue';
    import { getObjectTools } from '../../composables/ObjectTools.js';
    import { getRecordTools } from '../../composables/RecordTools.js';
    export default {
        name: 'RecordEmailLog',
        props: {
            'obj': { type: Object },
            'isFullInfoContext': {type: Boolean }
        },
        setup(props) {
            const { objectIDToObjectTypeMap } = getObjectTools(props);
            const { getTitleURL } = getRecordTools(props);
            const owners = computed(() => {
                var owners = props.obj && props.obj.owners ? props.obj.owners.map((o) => { return { id: o.ownerID, name: o.ownerName, objectType: objectIDToObjectTypeMap.value[o.ownerTypeID], objectTypeID: o.ownerTypeID, active: o.ownerActive } }) : []
                return owners;
            })
            const email = computed(() => {
                var email = props.obj.emails && props.obj.emails.length ? props.obj && props.obj.emails[0] : props.obj;
                var preparedEmail = {
                    name: email.name ? email.name : props.obj.name,
                    outbound: email.outbound,
                    to: email.to,
                    from: email.from
                }

                let userUrl = '/user/' + email.entryUserID;
                let persons = [], person, company, ownerUrl;
                if (owners.value.length > 0) {
                    persons = owners.value.filter(o => o.objectType == 'person');
                    company = owners.value.find(o => o.objectType == 'company');
                }
                if (persons.length > 0) {
                    person = persons[0];
                    ownerUrl = getTitleURL(person);
                }

                if (!preparedEmail.to)
                    preparedEmail.to = "Unknown email";
                if (!preparedEmail.from)
                    preparedEmail.from = "Unknown email";

                //to fix
                if (preparedEmail.outbound && preparedEmail.to.includes(";")) {
                    var emailsTo = preparedEmail.to.split(";");
                    preparedEmail.to = emailsTo[0] + " and " + (emailsTo.length - 1) + " more";
                }

                //from fix
                if (!preparedEmail.outbound && preparedEmail.from.includes(";")) {
                    var emailsFrom = preparedEmail.from.split(";");
                    preparedEmail.from = emailsFrom[0] + " and " + (emailsFrom.length - 1) + " more";
                }

                if (email.outbound) {
                    return {
                        name: email.name ? email.name : props.obj.name,
                        outbound: true,
                        to: person ? person.name : preparedEmail.to,
                        toLength: persons.length,
                        toUrl: ownerUrl,
                        from: email.entryUserName,
                        fromUrl: userUrl,
                        company: company
                    }
                } else {
                    return {
                        name: email.name ? email.name : props.obj.name,
                        outbound: false,
                        to: email.entryUserName,
                        toUrl: userUrl,
                        from: person ? person.name : preparedEmail.from,
                        fromLength: persons.length,
                        fromUrl: ownerUrl,
                        company: company
                    }
                }
            })
            const emails = computed(() => {
                let emails = [];
                return emails;
                /*if (props.obj && props.obj.emails && props.isFullInfoContext) {
                    emails = props.obj.emails;
                } else if (props.obj && props.obj.emails) {
                    emails = [props.obj.emails[0]];
                } else {
                    emails = [props.obj];
                }
                return emails.map(email => {
                    let userUrl = '/user/' + email.entryUserID;
                    let person, company, ownerUrl;
                    if (owners.value.length > 0) {
                        person = owners.value.find(o => o.objectType == 'person');
                        company = owners.value.find(o => o.objectType == 'company');
                    }
                    if (person) ownerUrl = getTitleURL(person);
                    if (email.outbound) {
                        return {
                            name: email.name ? email.name : props.obj.name,
                            outbound: email.outbound,
                            to: person ? person.name : email.to,
                            toUrl: ownerUrl,
                            from: email.entryUserName,
                            fromUrl: userUrl,
                            company: company
                        }
                    } else {
                        return {
                            name: email.name ? email.name : props.obj.name,
                            outbound: email.outbound,
                            to: email.entryUserName,
                            toUrl: userUrl,
                            from: person ? person.name : email.from,
                            fromUrl: ownerUrl,
                            company: company
                        }
                    }
                });*/
            });
            return { getTitleURL, emails, email }
        }
    }
</script>

<style lang="scss">

</style>
<template>
    <input ref="inputRef"
           type="text"
           :value="formattedValue"
           :title="title"
           :size="calculatedSize"
           :class="hasValueClass"
           @focus="$emit('focus',$event)"
           :disabled="disabled" />
</template>

<script>
    import { useCurrencyInput } from "vue-currency-input";
    import { watch } from 'vue'

    export default {
        name: "CurrencyInputSimple",
        emits:['focus'],
        props: {
            modelValue: Number,
            options: Object,
            title: String,
            size: { type: Number, default: 5 },
            disabled: { type: Boolean, default: false },
        },
        computed: {
            calculatedSize() {
                if (this.size == 0) {
                    var fLength = this.formattedValue ? this.formattedValue.toString().length : 0
                    //console.log(this.formattedValue, fLength)
                    return (fLength > 5 ? fLength : 5)
                }
                return this.size
            },
            hasValueClass() {
                return (this.formattedValue && this.formattedValue.toString().length ? 'has-value ' : ' ') + this.$attrs.class
            }
        },
        setup(props) {
            const {
                inputRef,
                formattedValue,
                setOptions,
                setValue
            } = useCurrencyInput(props.options)

            watch(() => props.modelValue, (value) => { // Vue 2: props.value
                setValue(value)
            })

            watch(() => props.options, (options) => {
                setOptions(options)
            })

            return { inputRef, formattedValue, setValue }
        }
    };
</script>
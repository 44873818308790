<template>
    <div :key="'dashboard-'+dashID" ref="dashElement">
        <template v-if="!dashboard">
            <div class="margin-top margin-left">
                Loading dashboard...
                <spin-loader :showMe="true" />
            </div>
        </template>
        <template v-if="dashboard">
            <header class="page-header">
                <div class="flex-rows no-responsive">
                    <h2> {{ dashboardName }} </h2><div class="tag-keyword hide-on-mobile">{{ dashboard && dashboard.cards && dashboard.cards.length ? (dashboard.cards.length + ' Card' + (dashboard.cards.length > 1 ? 's' : '' )) : 'No Cards' }}</div>
                    <spin-loader :showMe="showSpinLoader" />
                    <div class="place-right flex-rows nowrap no-responsive">
                        <date-range-picker v-model="dateRangeModel" :ref="(el)=>setItemRef(el,'drPicker')" />
                        <dashboard-options v-if="dashboard"
                                           :dashboard="dashboard"
                                           :dashboards="dashboards"
                                           :dashboardGroup="dashboardGroup"
                                           @refreshcards="loadCards" />
                    </div>
                </div>
            </header>
            <section class="page-content">
                <div v-if="errorMessage" class="alert alert-danger error-container d-none"><strong>Error!</strong><span class="alertError">{{ errorMessage }}</span></div>
                <section v-if="cardContainers.length" :class="'grid gutter-below '+ colMap[colsNeeded-1]">
                    <template v-for="(container, index) in cardContainers" :key="index">
                        <section v-for="(col, colindex) in container.columns" :key="colindex" :class="'grid-item '+ (container.type == 'fullwidth' ? 'fullwidth' : colMap[colindex])">
                            <section :class="'grid-item '+colMap[colindex]" v-for="card in col.cards" :key="card.id">
                                <dashboard-card v-model="card.data"
                                                :key="card.id"
                                                :id="card.id"
                                                :card="card"
                                                :dashboard="dashboard"
                                                :dashboards="dashboards"
                                                @detailrequest="openSidebar" />
                            </section>
                        </section>
                    </template>
                </section>
                <button v-show="!showSpinLoader && dashboard && dashboard.cards && dashboard.cards.length > visibleCards.length" type="button" class="button-secondary" @click="viewMore" title="View More"><i class="fa fa-ellipsis-h"></i></button>
            </section>
        </template>
    </div>
    <record-sidebar :ref="(el)=>setItemRef(el,'outerSidebar')" :editorID="editorID" :detailsRequest="detailsRequest" @navigate="handleNavigate" v-model="sidebarShowing" />
</template>
<script>
    import { ref, inject, provide, watch, computed, onBeforeUpdate, onMounted, onBeforeUnmount } from 'vue'
    import { useStore } from 'vuex'
    import _cloneDeep from 'lodash/cloneDeep'
    import { getMqTools } from '../../composables/MqTools.js'
    import { useToast, useItemRefs } from '../../composables/ModelWrapper.js'
    import { sidebarSetup } from '../../composables/SidebarTools.js'
    import { getDateRangeTools } from '../../composables/DateRangeTools.js'

    import DashboardCard from './DashboardCard.vue'
    import DashboardOptions from './DashboardOptions.vue'
    import SpinLoader from '../baseComponents/SpinLoader.vue'
    import DateRangePicker from '../baseComponents/DateRangePicker.vue'
    import RecordSidebar from '../Records/RecordSidebar.vue'
    import { useRouter } from 'vue-router'

    export default {
        name: 'Dashboard',
        props: {
            'id': { default: 0 },
            'initialCardsShown': { type: Number, default: 8 }
        },
        components: {
            DashboardCard, DashboardOptions, SpinLoader, DateRangePicker, RecordSidebar
        },
        setup(props) {
            // tools
            var store = useStore()
            var toast = useToast()
            const router = useRouter()

            const emitter = inject("emitter")   // Inject event bus     
            const { itemRefs, setItemRef, itemRefsReset } = useItemRefs()

            onBeforeUpdate(() => {
                itemRefsReset()
            })
            const { detailsRequest, editorID, sidebarShowing, openSidebar, fetchDetailsRequest, sidebarNavigate } = sidebarSetup()
            const { dateRangeMap } = getDateRangeTools()

            // data
            const dashElement = ref(null)
            const errorMessage = ref(null)
            const showSpinLoader = ref(false)
            const appliedSourceKeys = ref([])
            const cardsShown = ref(props.initialCardsShown)

            // static data
            const colMap = { 'wide': 'fullwidth', "0": 'one', "1": 'two', "2": 'three', "3": 'four' }

            // computed

            const user = computed(() => store.state.userInfo.user)
            const allDashboards = computed(() => store.state.dashboards)
            const dashboardGroup = computed(() => store.getters['userInfo/dashboardGroup'])
            const emailTemplates = computed(() => store.getters['letterwizard/emailTemplates'])

            const dashboards = computed(() => dashboardGroup.value ? dashboardGroup.value.dashboards : null)
            const dashID = computed(() => props.id ? props.id : (dashboards.value ? dashboards.value[0].id : null))
            const dashboard = computed(() => dashID.value && allDashboards.value ? allDashboards.value[dashID.value] : null)
            const dashboardName = computed(() => dashboard.value && dashboard.value.name ? dashboard.value.name : '...loading')
            const countMessage = computed(() => dashboardGroup.value.dashboards.length < 9 ? dashboardGroup.value.dashboards.length.toString() : "9+")
            const dashboardDateRangeKey = computed(() => dashboard.value ? dashboard.value.dateRangeKey : null)
            const dateRangeModel = computed({
                get: () => {
                    return dashboardDateRangeKey.value && dateRangeMap.value ? dateRangeMap.value[dashboardDateRangeKey.value] : null
                },
                set: (newVal) => {
                    var newKey = newVal ? newVal.key : null
                    if (dashboard.value && newKey && newKey != dashboardDateRangeKey.value) {
                        changeDateRange(newVal)
                    }
                }
            })

            const { thisScreenOrLargerComputed } = getMqTools('laptop')
            const colsNeeded = computed(() => thisScreenOrLargerComputed.value ? 2 : 1)
            const colClass = computed(() => colMap[(colsNeeded.value - 1).toString()])
            const visibleCards = computed(() => dashboard.value && dashboard.value.cards ? dashboard.value.cards.slice(0, cardsShown.value) : [])


            const cardContainers = computed(() => {
                var containers = []
                var fullCardOffset = 0
                var prevContainerType
                visibleCards.value.forEach((card, index) => {
                    var containerType = card.fullScreen ? 'fullwidth' : 'grid'
                    var container = containers[containers.length - 1]

                    if (prevContainerType != containerType) {
                        //when moving from wide to grid, we'll need to add an offset if the grid dash has an odd index
                        //keep the offset until there's another container change
                        fullCardOffset = (containerType == 'grid' && index % 2) ? 1 : 0
                    }

                    if (container && container.type == containerType) {
                        var colIndex = containerType == 'fullwidth' ? 0 : (index + fullCardOffset) % colsNeeded.value

                        if (container.columns[colIndex])
                            container.columns[colIndex].cards.push(card)
                        else
                            container.columns.push({ cards: [card] })
                    }
                    else {
                        container = {
                            type: containerType,
                            columns: [{ cards: [card] }]
                        }
                        containers.push(container)
                    }

                    prevContainerType = containerType

                })
                //console.log('cardContainers', containers)
                return containers
            })
            const cardData = computed(() => {
                var data = {};
                dashboard.value.cards.forEach((card) => {
                    data[card.id] = card.data
                })
                return data;
            })
            const cardMap = computed(() => {
                var cm = {}
                visibleCards.value.forEach((card) => {
                    var sourceKey = card.sourceObjectType + "::" + card.sourceID.toString()
                    if (!cm[sourceKey]) {
                        cm[sourceKey] = []
                    }
                    cm[sourceKey].push(card.id)
                })
                return cm
            })
            const sourceKeys = computed(() => Object.keys(cardMap.value))
            const detailsRequestObject = computed(() => detailsRequest.value ? detailsRequest.value.obj : null)
            const detailsRequestObjectKey = computed(() => detailsRequestObject.value ? detailsRequestObject.value.objectKey : null)

            // methods
            const loadCards = (srcKeys) => {
                if (!srcKeys)
                    srcKeys = sourceKeys.value

                if (!showSpinLoader.value && srcKeys && srcKeys.length) {
                    showSpinLoader.value = true
                    loadCardsPromise(srcKeys).then((reqIDs) => {
                        if (reqIDs.includes(dashID.value)) {
                            showSpinLoader.value = false
                            toast.add({ severity: 'info', summary: 'Cards Loaded', life: 3000 })
                        }
                    }).catch((errorIDs) => {
                        if (errorIDs.includes(dashID.value)) {
                            showSpinLoader.value = false
                            toast.add({ severity: 'error', summary: 'Cards loaded with errors', life: 3000 })
                        }
                    })
                }
            }

            const loadCardsPromise = (srcKeys) => {
                if (!srcKeys)
                    srcKeys = sourceKeys.value

                console.log('loading cards')
                let promises = []
                for (let i = 0; i < srcKeys.length; i++) {
                    promises.push(new Promise((resolve, reject) => {
                        store.dispatch('dashboards/fetchCardData', { 'dashboardID': dashID.value, 'cardIDs': cardMap.value[srcKeys[i]] }).then((res) => {
                            resolve(res.dashboardID)
                        }).catch(error => {
                            toast.add({ severity: 'error', summary: 'Error loading card data', detail: error.message, life: 3000 })
                            reject(error.dashboardID)
                        })
                    })
                    )
                }
                return Promise.all(promises)
            }
            const viewMore = () => {
                var srcKeys = _cloneDeep(sourceKeys.value)
                cardsShown.value += props.initialCardsShown

                var newSourceKeys = sourceKeys.value.filter(x => !srcKeys.includes(x))
                loadCards(newSourceKeys)
            }
            const doFilterRequest = (opt) => {
                console.log('applyFilter-dashboard', opt)
                var url = '/search/vue?' + encodeURIComponent(opt.filter) + '=' + encodeURIComponent(opt.filterVal) + '&docType=' + encodeURIComponent(opt.docType ?? 'Company')
                window.open(url, '_blank')
            }
            const changeDateRange = (dateRange) => {
                if (dateRange && dateRange.key && dashboard.value) {
                    if (dashboard.value.dateRangeKey != dateRange.key) {
                        showSpinLoader.value = true
                        console.log('changingDateRange')
                        store.dispatch('dashboards/changeDateRangeDashboard', {
                            'id': dashboard.value.id,
                            'dateRangeKey': dateRange.key
                        }).then(() => {
                            showSpinLoader.value = false
                            toast.add({ severity: 'success', summary: 'Date Range Change', detail: 'changed to ' + dateRange.description, life: 3000 })
                            store.dispatch('dashboards/fetchDashboard', { 'id': dashboard.value.id }).then(() => {
                                loadCards()
                                dashboard.value.dateRangeKey = dateRange.key

                            }).catch(error => {
                                //itemRefs.value.drPicker.selectDateRange(dashboard.value.dateRangeKey)
                                toast.add({ severity: 'error', summary: 'Error changing Date Range', detail: error, life: 3000 })
                            })
                        }).catch(error => {
                            showSpinLoader.value = false
                            //itemRefs.value.drPicker.selectDateRange(dashboard.value.dateRangeKey)
                            toast.add({ severity: 'error', summary: 'Error changing Date Range', detail: error, life: 3000 })
                        })
                    }
                }
            }
            const handleNavigate = (direction) => {
                var refSideBar = itemRefs.value && itemRefs.value.outerSidebar ? itemRefs.value.outerSidebar : null
                if (cardData.value[detailsRequest.value.cardID] && cardData.value[detailsRequest.value.cardID].hits) {
                    sidebarNavigate(direction, refSideBar, getHit, cardData.value[detailsRequest.value.cardID].hits.length)
                }
            }
            const getHit = (newIndex) => {
                return { 'obj': cardData.value[detailsRequest.value.cardID].hits[newIndex], 'listIndex': newIndex, 'cardID': detailsRequest.value.cardID }
            }
            const dashboardHitsUpdate = (req) => {
                if (dashboard.value && dashboard.value.cards) {
                    dashboard.value.cards.forEach((card, idx) => {
                        if (card && card.data && card.data.hits) {
                            card.data.hits.forEach((hit, jdx) => {
                                if (hit && hit.objectKey == req.objectKey) {
                                    store.commit('dashboards/UPDATE_CARD_HIT', { dashboardID: dashboard.value.id, cardIDX: idx, hitIDX: jdx, newHit: req.newObject })
                                }
                            })
                        }
                    })
                }
                if (detailsRequest.value && detailsRequest.value.obj && detailsRequest.value.obj.objectKey == req.objectKey)
                    detailsRequest.value.obj = req.newObject
            }

            const handleStoreLoad = (req) => {
               //force fetch the dashboard if it isn't in the store
                if (req.call == 'loadUserDashboards' && !dashboard.value) {

                    console.log('current dashboard not found in the store - fetching...')

                    store.dispatch('dashboards/fetchDashboard', { 'id': dashID.value })
                        .catch(error => {
                        toast.add({ severity: 'error', summary: 'Error fetching Dashboard', detail: error, life: 3000 })

                        setTimeout(function () {
                            router.push('/dashboard/home')
                        }, 3000)
                    })
                }
            }

            const initDashboard = () => {
                dashElement.value = null
                errorMessage.value = null
                showSpinLoader.value = false
                appliedSourceKeys.value = []
                cardsShown.value = props.initialCardsShown

                checkLoadCards()
            }

            const checkLoadCards = () => {
                if (sourceKeys.value && sourceKeys.value.length) {
                    var srcKeys = _cloneDeep(sourceKeys.value)
                    if (!srcKeys.every(r => appliedSourceKeys.value.includes(r))) {
                        appliedSourceKeys.value = srcKeys
                        loadCards()
                    }
                }
            }

            // watch
            watch(dashID, (newID, oldID) => {
                if (oldID != newID) {
                    console.log('active dashboard changed - updating')
                    initDashboard()
                    handleStoreLoad({ call: 'loadUserDashboards'})
                }
            })
            watch(sourceKeys, () => {
                checkLoadCards()
            })

            // life cycle
            onMounted(() => {
                initDashboard()

                emitter.on("objectUpdate", dashboardHitsUpdate)
                emitter.on("applyFilter", doFilterRequest)
                emitter.on("storeload", handleStoreLoad)
            })
            onBeforeUnmount(() => {
                emitter.off("objectUpdate", dashboardHitsUpdate)
                emitter.off("applyFilter", doFilterRequest)
                emitter.off("storeload", handleStoreLoad)
            })

            provide('highlightText', null)

            return {
                // data
                dashElement, cardData, detailsRequest, errorMessage, showSpinLoader, appliedSourceKeys, cardsShown, sidebarShowing,
                // static data
                colMap, editorID,
                // computed
                user, allDashboards, dashboardGroup, emailTemplates, dashboards, dateRangeMap, dashboardDateRangeKey, dateRangeModel,
                dashID, dashboard, dashboardName, countMessage,
                colsNeeded, colClass, visibleCards, cardContainers, cardMap, sourceKeys, detailsRequestObject, detailsRequestObjectKey,
                // methods
                loadCards, loadCardsPromise, viewMore, doFilterRequest, 
                changeDateRange, setItemRef, itemRefs, itemRefsReset, handleStoreLoad,
                openSidebar, fetchDetailsRequest, sidebarNavigate, getHit, dashboardHitsUpdate, handleNavigate,
                // event bus
                emitter
            }
        },
        watch: {           
            "dashboardName": {
                handler(newVal) {
                    document.title = 'MandAsoft - ' + newVal
                }
            }
        }
    }
</script>
<style scoped lang="scss">
</style>
